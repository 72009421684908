import { useEffect, useMemo, useRef } from "react";

import { ConsumerTripContentItemProductListType } from "@holibob-packages/graphql-types";

import { useConsumerTripRecentlyViewedProductListQuery } from "../../apiHooks/graphql";
import { ShelfDisplayEvent, ShelfFirstScrollEvent, ShelfProductClickEvent } from "../../custom-events";
import { useOnlyFirstCall } from "../../hooks";
import {
    ConsumerTripShelfProductSlider,
    ConsumerTripShelfProductSliderProps,
    ConsumerTripShelfProductSliderSkeleton,
} from "./ConsumerTripShelfProductSlider";
import { useConsumerTripHeaderData } from "./common/useConsumerTripHeaderData";

type ConsumerTripShelfRecentlyViewedProps = Omit<
    ConsumerTripShelfProductSliderProps,
    "products" | "title" | "subTitle" | "Icon" | "onProductClick" | "onScroll"
> & {
    consumerTripId: string;
};

export function ConsumerTripShelfRecentlyViewed({
    consumerTripId,
    ...otherProps
}: ConsumerTripShelfRecentlyViewedProps) {
    const headerData = useConsumerTripHeaderData({
        type: ConsumerTripContentItemProductListType.RecentlyViewed,
        typeParams: [],
    });
    const { loading, data } = useConsumerTripRecentlyViewedProductListQuery({
        variables: { consumerTripId },
        skip: !consumerTripId,
        fetchPolicy: "no-cache",
    });

    const products = useMemo(() => data?.consumerTripRecentlyViewedProductList?.nodes ?? [], [data]);
    const ref = useRef<HTMLDivElement>(null);

    const onFirstScroll = useOnlyFirstCall(() => {
        ref.current?.dispatchEvent(new ShelfFirstScrollEvent(ConsumerTripContentItemProductListType.RecentlyViewed));
    });

    const onProductClickHandler: ConsumerTripShelfProductSliderProps["onProductClick"] = (event, data) => {
        event.target.dispatchEvent(
            new ShelfProductClickEvent({ ...data, shelfType: ConsumerTripContentItemProductListType.RecentlyViewed })
        );
    };

    useEffect(() => {
        if (products.length > 1) {
            ref.current?.dispatchEvent(
                new ShelfDisplayEvent({
                    itemList: products.map(({ id, name }) => ({ id: id, name: name ?? "" })),
                    shelfType: ConsumerTripContentItemProductListType.RecentlyViewed,
                })
            );
        }
    }, [products]);

    if (!consumerTripId) {
        return null;
    }

    if (loading) {
        return <ConsumerTripShelfProductSliderSkeleton mode={"normal"} maxProducts={20} />;
    }

    if (products.length < 1) {
        return null;
    }

    return (
        <ConsumerTripShelfProductSlider
            products={products}
            ref={ref}
            {...otherProps}
            onScroll={onFirstScroll}
            onProductClick={onProductClickHandler}
            {...headerData}
        />
    );
}
