import { Skeleton, SkeletonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import { ConsumerTripStoriesProductCard } from "./ConsumerTripStoriesProductCard";

type ConsumerTripStoriesProductCardSkeletonProps = SkeletonProps;
export function ConsumerTripStoriesProductCardSkeleton(props: ConsumerTripStoriesProductCardSkeletonProps) {
    return (
        <ProductCardSkeleton variant="rounded" {...props}>
            <ConsumerTripStoriesProductCard name={null} price={null} id={""} slug={null} />
        </ProductCardSkeleton>
    );
}

const ProductCardSkeleton = styled(Skeleton)(({ theme }) => ({
    background: "linear-gradient(180deg, #232323 0%, #323232 100%)",
    borderRadius: theme.roundness,
}));
