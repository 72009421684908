import { Hbml, HbmlElementNode, HbmlNode, isElementNode } from "@holibob-packages/hbml";

export const isTransparentHeaderAllowedForHBML = ([firstElement]: Hbml): boolean => {
    if (!isElementNode(firstElement)) {
        return false;
    }

    if (isValidHero(firstElement)) {
        return true;
    }

    if (isValidConsumerTrip(firstElement)) {
        return !!getAttribute(firstElement, "hero");
    }

    const [firstChildOfSection] = firstElement.children;

    if (!isValidFullWidthSection(firstElement)) {
        return false;
    }

    const isComponentSupportingHero =
        isValidCurationsV2(firstChildOfSection) || isValidCurationProductsV2(firstChildOfSection);

    return isComponentSupportingHero && getAttribute(firstChildOfSection, "mode") === "hero";
};

function isValidFullWidthSection(element: HbmlNode) {
    return (
        isElementNode(element) && element.type === "section" && getAttribute(element, "contentWidth") === "full-width"
    );
}

function isValidHero(element: HbmlNode) {
    return isElementNode(element) && element.type === "hero";
}

function isValidCurationsV2(element: HbmlNode): element is HbmlElementNode {
    return isElementNode(element) && element.type === "curations-v2";
}

function isValidCurationProductsV2(element: HbmlNode): element is HbmlElementNode {
    return isElementNode(element) && element.type === "curation-products-v2";
}

function isValidConsumerTrip(element: HbmlNode): element is HbmlElementNode & { type: "consumer-trip" } {
    return isElementNode(element) && element.type === "consumer-trip";
}

function getAttribute<T>(element: HbmlElementNode, name: string) {
    return (element as Record<string, unknown>)[name] as T | undefined;
}
