import { styled } from "@mui/material/styles";

import { ModalDeprecated, ModalLayout } from "../ModalDeprecated";
import {
    QuestionnaireV2Provider,
    QuestionnaireV2ProviderProps,
    useQuestionnaireV2Context,
} from "./QuestionnaireV2Provider";

const QuestionnaireV2ModalWrapper = styled(ModalDeprecated)(({ theme }) => ({
    "& .MuiPaper-root": {
        width: "100%",
        height: "100%",
        padding: 0,
        gap: 0,
        [theme.breakpoints.up("sm")]: {
            width: "auto",
            minWidth: 600,
            height: "auto",
        },
        [theme.breakpoints.up("lg")]: {
            width: "auto",
            minWidth: 1000,
            height: "auto",
        },
    },
    "& [data-ref='modal-content']": {
        display: "flex",
        flexDirection: "column",
    },
}));

type QuestionnaireV2ContainerContentProps = React.BaseHTMLAttributes<HTMLDivElement> & {
    contentRef: React.RefObject<HTMLDivElement>;
};

const QuestionnaireV2ContainerContent = ({ contentRef, ...props }: QuestionnaireV2ContainerContentProps) => {
    const questionnaireContext = useQuestionnaireV2Context();
    const { steps, open, onClose, currentStep } = questionnaireContext;
    const PageComponent = steps[currentStep].Component;

    return (
        <QuestionnaireV2ModalWrapper open={!!open} onClose={onClose} ref={contentRef}>
            <ModalLayout {...props}>
                <PageComponent {...questionnaireContext} />
            </ModalLayout>
        </QuestionnaireV2ModalWrapper>
    );
};

type QuestionnaireV2ContainerProps = Omit<QuestionnaireV2ProviderProps, "children"> &
    React.BaseHTMLAttributes<HTMLDivElement> & {
        contentRef: React.RefObject<HTMLDivElement>;
    };

export function QuestionnaireV2Container(props: QuestionnaireV2ContainerProps) {
    const { steps, open, onNext, onClose, onSubmit, onBack, ...modalLayoutProps } = props;
    return (
        <QuestionnaireV2Provider
            steps={steps}
            open={open}
            onNext={onNext}
            onBack={onBack}
            onClose={onClose}
            onSubmit={onSubmit}
        >
            <QuestionnaireV2ContainerContent {...modalLayoutProps} />
        </QuestionnaireV2Provider>
    );
}
