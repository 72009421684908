import { styled } from "@mui/material/styles";
import { HTMLAttributes } from "react";

import { urlProduct } from "@holibob-packages/url";
import { AssetUrl } from "@holibob-packages/vault";

import { useFormattedPrice, useNextTranslation } from "../../hooks";
import { Link } from "../../navigation";
import { ImageTile } from "../ImageTile";
import { Typography } from "../Typography";

export type ConsumerTripStoriesProductCardProps = {
    id: string;
    imageAssetUrl?: AssetUrl;
    name: string | null;
    price?: {
        gross?: number;
        currency?: string;
    } | null;
    slug: string | null;
} & HTMLAttributes<HTMLDivElement>;
export function ConsumerTripStoriesProductCard({
    id,
    imageAssetUrl,
    name,
    price,
    slug,
    ...restProps
}: ConsumerTripStoriesProductCardProps) {
    const [t] = useNextTranslation("general");
    const formattedPrice = useFormattedPrice(price?.gross ?? 0, { currency: price?.currency });
    const productUrl = urlProduct(slug ?? id);
    return (
        <Link href={productUrl}>
            <Container {...restProps}>
                <Image alt={name ?? ""} imageAssetUrl={imageAssetUrl} height={152} minWidth={145} maxWidth={145} />
                <TitleContainer>
                    <Title variant="label" color="light" lineClamp={2}>
                        {name}
                    </Title>
                    <Price variant="label" color="light">
                        {t("from")} {formattedPrice}
                    </Price>
                </TitleContainer>
            </Container>
        </Link>
    );
}

const Container = styled("div")(({ theme }) => ({
    display: "flex",
    width: 145,
    height: 152,
    borderRadius: theme.roundness,
    position: "relative",
}));

const Image = styled(ImageTile)(({ theme }) => ({
    borderRadius: theme.roundness,
}));

const TitleContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: theme.roundness,
    background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 53.65%, rgba(0, 0, 0, 0.60) 73.96%)`,
    backgroundBlendMode: `plus-darker, normal`,
    boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)`,
}));

const Title = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(0, 1),
}));

const Price = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(0, 1, 1, 1),
}));
