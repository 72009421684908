import { ConsumerTripContentItemProductListType } from "@holibob-packages/graphql-types";

import { ConsumerTripContentItemProductListItemFragment } from "../gql-request";

export type ConsumerTripPersonalizationShelf = ConsumerTripContentItemProductListItemFragment;

export type FinalConsumerTripShelfListInput = {
    shelfList: ConsumerTripContentItemProductListItemFragment[];
    placeId: string;
    shelfTypeListWithData: Set<ConsumerTripContentItemProductListType>;
};

export function* computeFinalConsumerTripShelfList({
    shelfList,
    shelfTypeListWithData,
}: FinalConsumerTripShelfListInput): Generator<ConsumerTripPersonalizationShelf, undefined, undefined> {
    const shelfListWithData = shelfList.filter((shelf) => shelfTypeListWithData.has(shelf.type));

    yield* shelfListWithData.slice(0, 4);

    yield* shelfListWithData.slice(4);
}
