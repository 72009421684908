import { styled } from "@mui/material/styles";

const PaginationWrapper = styled("div")({
    display: "flex",
    '&[data-alignment="left"]': {
        justifyContent: "flex-start",
    },
    '&[data-alignment="center"]': {
        justifyContent: "center",
    },
    '&[data-alignment="right"]': {
        justifyContent: "flex-end",
    },
});

type PaginationContainerProps = {
    align: "left" | "right" | "center";
    pageCount: number;
    children: React.ReactNode;
};

export const PaginationContainer = ({ align, pageCount, children }: PaginationContainerProps) => {
    if (pageCount < 2) return null;
    return <PaginationWrapper data-alignment={align}>{children}</PaginationWrapper>;
};
