import { TFunction } from "i18next";
import { ComponentProps, ComponentType, SVGProps } from "react";

import { ConsumerTripContentItemProductListType } from "@holibob-packages/graphql-types";

import { ConsumerTripContentItemProductListItemFragment } from "../../../apiHooks/graphql";
import { useNextTranslation } from "../../../hooks";
import { AirportStory } from "../../../icons/Story/Airport";
import { BeyondTheCityStory } from "../../../icons/Story/BeyondTheCity";
import { BusinessStory } from "../../../icons/Story/Business";
import { FamilyStory } from "../../../icons/Story/Family";
import { HiddenGemsStory } from "../../../icons/Story/HiddenGems";
import { InterestsStory } from "../../../icons/Story/Interests";
import { LikelyToSellOutStory } from "../../../icons/Story/LikelyToSellOut";
import { NearbyStory } from "../../../icons/Story/Nearby";
import { PersonalStory } from "../../../icons/Story/Personal";
import { PrivateToursStory } from "../../../icons/Story/PrivateTours";
import { RainStory } from "../../../icons/Story/Rain";
import { ShortToursStory } from "../../../icons/Story/ShortTours";
import { TopAttractionsStory } from "../../../icons/Story/TopAttractions";
import { ViewedStory } from "../../../icons/Story/Viewed";

export type ConsumerTripHeaderData = {
    title: string;
    subTitle?: string;
    Icon: React.ComponentType<SVGProps<SVGSVGElement>>;
};

export const TypeIconMap: { [x in ConsumerTripContentItemProductListType]: ComponentType<ComponentProps<"svg">> } = {
    AIRPORT_TRANSFER_AND_LOUNGE: AirportStory,
    BUSINESS: BusinessStory,
    FAMILY_FRIENDLY: FamilyStory,
    HIDDEN_GEMS: HiddenGemsStory,
    LIKED_SYSTEM_TAGS: InterestsStory,
    LIKELY_TO_SELL_OUT: LikelyToSellOutStory,
    LONG_HAUL: BeyondTheCityStory,
    LONG_TRIP: BeyondTheCityStory,
    NEARBY: NearbyStory,
    PAX_4_OR_MORE: PrivateToursStory,
    PERSONAL: PersonalStory,
    RAINING_DURING_STAY: RainStory,
    RAINING_NEXT_FEW_DAYS: RainStory,
    RECENTLY_VIEWED: ViewedStory,
    SHORT_TRIP: ShortToursStory,
    TOP_ATTRACTIONS: TopAttractionsStory,
};

export const getTitleAndSubtitleForShelves = ({
    type,
    typeParams,
    t,
}: ConsumerTripHeaderDataProps & { t: TFunction }) => {
    let title = t(`consumerTrip.shelf.${type.toLowerCase()}.title`, { params: typeParams?.join(", ") });
    const subtitle = t(`consumerTrip.shelf.${type.toLowerCase()}.subTitle`, {
        params: typeParams?.join(", "),
        defaultValue: "",
    });

    if (type === "NEARBY") {
        const params = typeParams?.join(",");
        title = params?.length
            ? t(`consumerTrip.shelf.${type.toLowerCase()}.title`, { params })
            : t(`consumerTrip.shelf.${type.toLowerCase()}.titleWithoutParams`);
    }

    return { title, subtitle };
};

export type ConsumerTripHeaderDataProps = {
    type: ConsumerTripContentItemProductListType;
    typeParams: ConsumerTripContentItemProductListItemFragment["typeParams"];
};

export function useConsumerTripHeaderData({ type, typeParams }: ConsumerTripHeaderDataProps): ConsumerTripHeaderData {
    const [t] = useNextTranslation("hbml");
    const { title, subtitle } = getTitleAndSubtitleForShelves({ type, typeParams, t });

    return {
        title,
        subTitle: subtitle,
        Icon: TypeIconMap[type],
    };
}
