import { useLanguage } from ".";

const STYLE = "unit";

export function useFormattedTemperature(temperature: number, options: Intl.NumberFormatOptions = {}) {
    const languageFromContext = useLanguage();

    return new Intl.NumberFormat(languageFromContext.code, {
        ...options,
        style: STYLE,
        unit: options.unit,
        unitDisplay: options.unitDisplay ?? "short",
    }).format(temperature);
}
