import { styled } from "@mui/material/styles";
import { ComponentProps, ComponentType, useCallback, useRef, useState } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { ConsumerTripContentItemProductListType } from "@holibob-packages/graphql-types";

import { ConsumerTripProductList, ConsumerTripProductsQueryResult } from "../../apiHooks/useConsumerTripProductsQuery";
import { countryGreetingMapper } from "../../constants/countryGreetingMapper";
import { StoryDisplayEvent, StoryProductsScrollEvent } from "../../custom-events";
import { StoryProductClickEvent } from "../../custom-events";
import { useNextTranslation } from "../../hooks";
import { PersonalStory } from "../../icons/Story/Personal";
import { ProductCardV2Props } from "../../product";
import { ProductItem } from "../../types/ProductItem";
import { formatPersonName } from "../../utils/formatters/formatName";
import { ConsumerTripPersonalizationShelf } from "../../utils/storiesHelper";
import { HbmlCurationProductsProductHrefFactory } from "../HbmlComponents";
import { HeroImageConsumerTrip } from "../HbmlComponents/HbmlConsumerTripNode";
import { MainColumnWrapper } from "../MainColumnWrapper";
import { Stories } from "../Stories/Stories";
import { ConsumerTripSearchProducts } from "./ConsumerTripSearchProducts";
import { ConsumerTripStoriesSkeleton } from "./ConsumerTripStoriesSkeleton";
import { ConsumerTripStoryContent } from "./ConsumerTripStoryContent";
import { ConsumerTripStoryPersonal } from "./ConsumerTripStoryPersonal";
import { TypeIconMap, getTitleAndSubtitleForShelves } from "./common/useConsumerTripHeaderData";

type ConsumerTripStoriesContainerProps = {
    rules: ConsumerTripPersonalizationShelf[];
    products: ConsumerTripProductsQueryResult;
    curationId?: string;
    consumerTrip: Pick<HeroImageConsumerTrip, "consumerTripId" | "consumer">;
    placeId: string;
    consumerTripSystemTagIdList: string[];
    productHrefFactory: HbmlCurationProductsProductHrefFactory;
    ProductCardComponent: React.ComponentType<ProductCardV2Props>;
    country: string;
    consumerName?: string | null;
    isLoading?: boolean;
    curationSlugIdList?: string[];
};

const ANIMATION_DURATION_IN_MS = 600;

type StoryMetaDataBase = {
    storyTitle: string;
    shelfType: ConsumerTripContentItemProductListType;
    IconComponent: ComponentType<ComponentProps<"svg">>;
};

type StoryMetaDataDefault = StoryMetaDataBase & {
    title: string;
    loading: boolean;
    subtitle: string;
};

type StoryMetaDataCustomComponent = StoryMetaDataBase & {
    Component: JSX.Element;
};

export type StoryMetaData = StoryMetaDataDefault | StoryMetaDataCustomComponent;

export type HandleAutoPlayChange = (
    input: { shouldAutoplay: true } | { shouldAutoplay: false; shouldRestart?: boolean }
) => void;

export function ConsumerTripStoriesContainer(props: ConsumerTripStoriesContainerProps) {
    const {
        isLoading = false,
        rules,
        products,
        consumerTrip,
        placeId,
        curationId,
        consumerTripSystemTagIdList,
        curationSlugIdList,
        productHrefFactory,
        ProductCardComponent,
        country,
        consumerName,
    } = props;
    const [t] = useNextTranslation("hbml");
    const nodeRef = useRef<HTMLDivElement | null>(null);
    const [selectedShelfType, setSelectedShelfType] = useState<ConsumerTripContentItemProductListType>(
        Object.keys(products)[0] as ConsumerTripContentItemProductListType
    );
    const [autoPlay, setAutoPlay] = useState(true);
    const [scrolledStoriesList, setScrolledStoriesList] = useState<ConsumerTripContentItemProductListType[]>([]);
    const displayedStoriesListRef = useRef<ConsumerTripContentItemProductListType[]>([]);

    const restartTimerRef = useRef<NodeJS.Timeout | null>(null);
    const abortRestartTimerRef = useRef<AbortController | null>(null);

    const handleAutoPlayChange: HandleAutoPlayChange = (input) => {
        setAutoPlay(input.shouldAutoplay);

        if (restartTimerRef.current) {
            clearTimeout(restartTimerRef.current);
        }
        if (abortRestartTimerRef.current) {
            abortRestartTimerRef.current.abort();
        }

        if (input.shouldAutoplay === true || !input.shouldRestart) {
            return;
        }

        const abortController = new AbortController();
        abortRestartTimerRef.current = abortController;

        restartTimerRef.current = setTimeout(() => {
            if (!abortController.signal.aborted) {
                setAutoPlay(true);
            }
        }, 4000);
    };

    const onProductClick = (item: ProductItem, position: number) => {
        nodeRef.current?.dispatchEvent(
            new StoryProductClickEvent({
                item,
                position,
                shelfType: selectedShelfType,
            })
        );
    };

    const handleScroll = () => {
        if (!scrolledStoriesList.includes(selectedShelfType)) {
            setScrolledStoriesList((prev) => [...prev, selectedShelfType]);
            nodeRef.current?.dispatchEvent(new StoryProductsScrollEvent(selectedShelfType));
        }
    };

    const onStoryProductsDisplay = useCallback(
        (productList: ConsumerTripProductList, shelfType: ConsumerTripContentItemProductListType) => {
            if (productList.length > 1 && !displayedStoriesListRef.current.includes(shelfType)) {
                displayedStoriesListRef.current = [...displayedStoriesListRef.current, shelfType];
                nodeRef.current?.dispatchEvent(
                    new StoryDisplayEvent({
                        itemList: productList.map(({ id, name }) => ({ id: id, name: name ?? "" })),
                        shelfType: shelfType,
                    })
                );
            }
        },
        []
    );

    const storyMetaDataList: StoryMetaData[] = [
        ...rules.map(({ type, typeParams }) => ({
            ...getTitleAndSubtitleForShelves({ type, typeParams, t }),
            storyTitle: t(`consumerTrip.shelf.${type.toLowerCase()}.storyTitle`, { params: typeParams?.join(", ") }),
            IconComponent: TypeIconMap[type],
            shelfType: type,
            loading: products[type]?.loading ?? true,
        })),
        {
            storyTitle: t("consumerTrip.shelf.personal.storyTitle"),
            IconComponent: PersonalStory,
            shelfType: ConsumerTripContentItemProductListType.Personal,
            Component: (
                <ConsumerTripStoryPersonal
                    consumerTrip={consumerTrip}
                    placeId={placeId}
                    curationId={curationId}
                    setAutoPlay={handleAutoPlayChange}
                    consumerTripSystemTagIdList={consumerTripSystemTagIdList}
                    onProductClick={onProductClick}
                    onScroll={handleScroll}
                    onStoryProductsDisplay={onStoryProductsDisplay}
                />
            ),
        },
    ];

    const handleStoryChange = (shelfType: ConsumerTripContentItemProductListType) => {
        setSelectedShelfType(shelfType);
    };

    const currentProductListToDisplay = products[selectedShelfType]!;
    const currentStoryMetaData = storyMetaDataList.find((x) => x.shelfType === selectedShelfType);
    const withSkeletonLoading =
        isLoading ||
        currentProductListToDisplay.loading ||
        !currentStoryMetaData ||
        (!("Component" in currentStoryMetaData) && currentProductListToDisplay.data.length === 0);

    return (
        <Wrapper>
            <TripOverviewContainer>
                <MainColumnWrapper>
                    <TripOverviewHeader>
                        <PersonalisedGreeting country={country} consumerName={consumerName ?? ""} />

                        <ConsumerTripSearchProducts
                            productHrefFactory={productHrefFactory}
                            filters={{ placeId, curationSlugIdList: curationSlugIdList }}
                            ProductCardComponent={ProductCardComponent}
                        />
                    </TripOverviewHeader>

                    <Stories
                        storyList={storyMetaDataList.slice(0, 5)}
                        onStoryChange={handleStoryChange}
                        autoPlay={autoPlay}
                        onAutoPlayChange={(shouldAutoplay) =>
                            handleAutoPlayChange(
                                shouldAutoplay ? { shouldAutoplay } : { shouldAutoplay, shouldRestart: true }
                            )
                        }
                    />
                    <ContentContainer>
                        <SwitchTransition mode="out-in">
                            <CSSTransition
                                key={selectedShelfType}
                                nodeRef={nodeRef}
                                classNames="fade"
                                timeout={ANIMATION_DURATION_IN_MS}
                            >
                                <div ref={nodeRef}>
                                    {withSkeletonLoading ? (
                                        <ConsumerTripStoriesSkeleton />
                                    ) : "Component" in currentStoryMetaData ? (
                                        currentStoryMetaData.Component
                                    ) : (
                                        <ConsumerTripStoryContent
                                            stopAutoPlay={() =>
                                                handleAutoPlayChange({ shouldAutoplay: false, shouldRestart: true })
                                            }
                                            subtitle={currentStoryMetaData.subtitle}
                                            title={currentStoryMetaData.title}
                                            productList={currentProductListToDisplay.data}
                                            onProductClick={onProductClick}
                                            onScroll={handleScroll}
                                            onStoryProductsDisplay={onStoryProductsDisplay}
                                            shelfType={selectedShelfType}
                                        />
                                    )}
                                </div>
                            </CSSTransition>
                        </SwitchTransition>
                    </ContentContainer>
                </MainColumnWrapper>
            </TripOverviewContainer>
        </Wrapper>
    );
}

function PersonalisedGreeting({ country, consumerName }: { country: string; consumerName: string }) {
    const countryGreeting = countryGreetingMapper[country.toLowerCase()] ?? "Hello";
    const formattedName = formatPersonName(consumerName);

    return (
        <PersonalisedGreetingWrapper>
            {countryGreeting},&nbsp;<span>{formattedName}</span>
        </PersonalisedGreetingWrapper>
    );
}

const Wrapper = styled("div")(() => ({
    display: "flex",
    justifyContent: "center",
    overflowX: "hidden",
}));

const ContentContainer = styled("div")({
    display: "flex",
    flexDirection: "column",

    "& .fade-enter": {
        opacity: 0,
        transform: "translateX(20%)",
    },
    "& .fade-exit": {
        opacity: 1,
        transform: "translateX(0%)",
    },
    "& .fade-enter-done": {
        opacity: 1,
    },
    "& .fade-enter-active": {
        opacity: 1,
        transform: "translateX(0%)",
        transition: `opacity ${ANIMATION_DURATION_IN_MS}ms, transform ${ANIMATION_DURATION_IN_MS}ms`,
    },
    "& .fade-exit-active": {
        opacity: 0,
        transform: "translateX(-20%)",
        transition: `opacity ${ANIMATION_DURATION_IN_MS}ms, transform ${ANIMATION_DURATION_IN_MS}ms`,
    },
});

const TripOverviewContainer = styled("div")(({ theme }) => ({
    background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 1) 100%)",
    borderTop: "1px solid rgba(245, 247, 249, 0.6)",
    padding: theme.spacing(3, 2),
    maxHeight: 400,
    width: "100%",
    overflowX: "hidden",
    "& [data-ref='main-column-wrapper']": {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(3),
        margin: 0,
        [theme.breakpoints.up("sm")]: {
            width: 1200,
        },
    },
    [theme.breakpoints.up("md")]: {
        margin: theme.spacing(0, 3),
        padding: theme.spacing(3, 3),
        width: "auto",
        borderRadius: theme.spacing(3),
    },
    [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(3, 4),
    },
}));

const TripOverviewHeader = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
});

const PersonalisedGreetingWrapper = styled("p")(({ theme }) => ({
    color: theme.palette.common.white,
    fontSize: 24,
    margin: 0,

    "& span": {
        fontWeight: 700,
    },
}));
