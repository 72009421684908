import { BookingProductCardV2 } from "containers/BookingProductCardV2";
import { DistributionChannelPageContentQuery } from "gql";
import { useConsumerTripInfo } from "hooks/useConsumerTripInfo";
import { useProductUrlGenerator } from "hooks/useProductUrlGenerator";

import { HbmlComponents, HbmlElementComponentProps } from "@holibob-packages/ui-core/components";

import { contentComponentPreset } from "./hbmlContentComponentPreset";

export type PageComponentPresetsParams = {
    distributionChannelPageContent?: DistributionChannelPageContentQuery["distributionChannelPageContent"];
    pagePath: string;
};

export const getPageComponentPresets = (params: PageComponentPresetsParams) => {
    const { distributionChannelPageContent, pagePath } = params;

    const pageContentComponentPreset = {
        ...contentComponentPreset,
        [HbmlComponents.HbmlElements.CONSUMER_TRIP]: ({ content }: HbmlElementComponentProps) => {
            const placeId = distributionChannelPageContent?.placeId;
            const heroTitle = distributionChannelPageContent?.metaTitle;
            const { consumerTripId } = useConsumerTripInfo();
            const productHrefFactory = useProductUrlGenerator();

            return (
                distributionChannelPageContent && (
                    <HbmlComponents.HbmlConsumerTripNode.nodeComponent
                        pagePath={pagePath}
                        ProductCardComponent={BookingProductCardV2}
                        productHrefFactory={productHrefFactory}
                        consumerTripId={consumerTripId}
                        content={content}
                        placeId={placeId}
                        curationId={content.curationId}
                        heroTitle={heroTitle}
                    />
                )
            );
        },
    };

    return HbmlComponents.extendStandardPreset(pageContentComponentPreset);
};
