import { DefaultContext } from "@apollo/client";

type CreateContextForRequestOptionsArgs = {
    currency?: string | null;
};

export function useCreateContextForRequestOptions() {
    return function ({ currency }: CreateContextForRequestOptionsArgs = {}): DefaultContext {
        return {
            headers: {
                ...(currency ? { "x-holibob-currency": currency } : {}),
            },
        };
    };
}
