import { ComponentProps } from "react";

export function LightningWeather(props: ComponentProps<"svg">) {
    return (
        <svg {...props} width="32" height="32" viewBox="0 0 32 32">
            <mask id="mask0_57233_2717" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_57233_2717)">
                <path d="M12.6462 24L14.7077 21.6712L12.7077 20.6712L15.0308 18H16.3539L14.2923 20.3288L16.2923 21.3288L13.9692 24H12.6462ZM6.64615 24L8.70768 21.6712L6.70768 20.6712L9.03077 18H10.3538L8.29233 20.3288L10.2923 21.3288L7.96923 24H6.64615ZM7.5 16C6.11153 16 4.93108 15.5138 3.95865 14.5414C2.98622 13.5689 2.5 12.3885 2.5 11C2.5 9.70642 2.9359 8.58782 3.8077 7.64422C4.67948 6.70064 5.77115 6.15641 7.0827 6.01152C7.57757 5.07436 8.25545 4.33814 9.11635 3.80287C9.97725 3.26762 10.9385 3 12 3C13.4359 3 14.6696 3.47596 15.701 4.42787C16.7324 5.37981 17.3327 6.56603 17.5019 7.98653C18.7288 8.06089 19.7019 8.48782 20.4212 9.2673C21.1404 10.0468 21.5 10.9577 21.5 12C21.5 13.109 21.1106 14.0529 20.3317 14.8317C19.5529 15.6106 18.609 16 17.5 16H7.5ZM7.5 15H17.5C18.3282 15 19.0353 14.7071 19.6212 14.1212C20.2071 13.5352 20.5 12.8282 20.5 12C20.5 11.1718 20.2071 10.4647 19.6212 9.87885C19.0353 9.29295 18.3282 9 17.5 9H16.5V8.5C16.5 7.25898 16.0603 6.19872 15.1808 5.31922C14.3013 4.43974 13.241 4 12 4C11.0974 4 10.2753 4.24552 9.53365 4.73655C8.79198 5.22757 8.23526 5.88462 7.86348 6.7077L7.74808 7H7.45C6.35898 7.03333 5.42789 7.43557 4.65672 8.20672C3.88557 8.97789 3.5 9.90898 3.5 11C3.5 12.1077 3.88974 13.0513 4.66922 13.8308C5.44872 14.6103 6.39232 15 7.5 15Z" />
            </g>
        </svg>
    );
}
