import { Skeleton, SkeletonProps, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Typography } from "../Typography";
import { ConsumerTripStoriesProductCardSkeleton } from "./ConsumerTripStoriesProductCardSkeleton";

export function ConsumerTripStoriesSkeleton() {
    return (
        <Stack>
            <TextSkeleton variant="text" width={200}>
                <Typography size="large" variant="body">
                    Loading
                </Typography>
            </TextSkeleton>
            <TextSkeleton variant="text" width={300}>
                <Typography size="large" variant="headline">
                    Loading
                </Typography>
            </TextSkeleton>
            <ConsumerTripStoriesProductsSkeleton />
        </Stack>
    );
}

type ConsumerTripStoriesProductCardSkeletonProps = {
    numberOfProducts?: number;
    animation?: SkeletonProps["animation"];
};
export function ConsumerTripStoriesProductsSkeleton({
    numberOfProducts = 3,
    animation,
    ...props
}: ConsumerTripStoriesProductCardSkeletonProps) {
    return (
        <ProductsContainer {...props}>
            {Array.from({ length: numberOfProducts }).map((_, index) => (
                <ConsumerTripStoriesProductCardSkeleton key={index} animation={animation} />
            ))}
        </ProductsContainer>
    );
}

const ProductsContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
    scrollSnapType: "x mandatory",
    gap: theme.spacing(2),
    paddingTop: theme.spacing(),
    "&::-webkit-scrollbar": {
        display: "none",
    },
}));

const TextSkeleton = styled(Skeleton)(({ theme }) => ({
    backgroundColor: theme.palette.grey[700],
}));
