export const formatPersonName = (name: string): string => {
    // Normalise spaces and handle spaces around hyphens
    const trimmedName = name.trim().split(" ").join(" ");
    const parts = trimmedName.split("-");
    const normalisedName = parts.map((part) => part.trim()).join("-");

    return normalisedName
        .split(" ")
        .filter((word) => word.length > 0)
        .map(capitaliseNameWord)
        .join(" ");
};

const capitaliseNameWord = (word: string): string => {
    if (!word) return "";

    const lowercaseWord = word.toLowerCase();

    // Recursively handle hyphenated names
    if (lowercaseWord.includes("-")) {
        return lowercaseWord
            .split("-")
            .map((part) => capitaliseNameWord(part))
            .join("-");
    }

    // Handle 'Mc' prefix - only capitalise remainder if more than one letter
    if (lowercaseWord.startsWith("mc") && lowercaseWord.length > 2) {
        const remainder = lowercaseWord.slice(2);
        return (
            "Mc" +
            (remainder.length > 1 ? remainder.charAt(0).toUpperCase() + remainder.slice(1) : remainder.toLowerCase())
        );
    }

    // Handle 'Mac' prefix - only capitalise remainder if more than one letter
    if (lowercaseWord.startsWith("mac") && lowercaseWord.length > 3) {
        const remainder = lowercaseWord.slice(3);
        return (
            "Mac" +
            (remainder.length > 1 ? remainder.charAt(0).toUpperCase() + remainder.slice(1) : remainder.toLowerCase())
        );
    }

    if (lowercaseWord.startsWith("o'")) {
        return "O'" + lowercaseWord.charAt(2).toUpperCase() + lowercaseWord.slice(3);
    }

    return lowercaseWord.charAt(0).toUpperCase() + lowercaseWord.slice(1);
};
