import { ComponentProps } from "react";

export function BeyondTheCityStory(props: ComponentProps<"svg">) {
    return (
        <svg {...props} width="40" height="40" viewBox="0 0 40 40">
            <g>
                <path d="M19.9789 3.31093L8 25.8562H21.1264V3.5681C21.1256 3.44013 21.0792 3.31612 20.9946 3.21594C20.91 3.11575 20.7922 3.04518 20.6599 3.01554C20.5277 2.9859 20.3887 2.9989 20.2652 3.05246C20.1417 3.10602 20.0409 3.19704 19.9789 3.31093Z" />
                <path d="M31.2875 25.8561L23 9.79159V25.8561H31.2875Z" />
                <path d="M4.57143 27.4706H35.4286C35.5801 27.4706 35.7255 27.5273 35.8326 27.6281C35.9398 27.729 36 27.8658 36 28.0084C36 28.9266 35.8079 29.8357 35.4345 30.684C35.0612 31.5322 34.514 32.303 33.8242 32.9522C33.1344 33.6014 32.3155 34.1164 31.4142 34.4678C30.5129 34.8192 29.547 35 28.5714 35H11.4286C9.45839 35 7.56891 34.2634 6.17578 32.9522C5.48597 32.303 4.93879 31.5322 4.56547 30.684C4.19215 29.8357 4 28.9266 4 28.0084C4 27.8658 4.0602 27.729 4.16737 27.6281C4.27453 27.5273 4.41988 27.4706 4.57143 27.4706Z" />
            </g>
        </svg>
    );
}
