import { ComponentProps } from "react";

export function SnowWeather(props: ComponentProps<"svg">) {
    return (
        <svg {...props} width="32" height="32" viewBox="0 0 32 32">
            <g>
                <path d="M15.933 28.6667C15.6016 28.6667 15.333 28.3981 15.333 28.0667V22.2103L10.7573 26.7251C10.5225 26.9567 10.1448 26.9554 9.91158 26.7222L9.81367 26.6243C9.57936 26.39 9.57936 26.0101 9.81367 25.7758L15.333 20.2564V16.6667H11.7433L6.22394 22.186C5.98962 22.4204 5.60972 22.4204 5.37541 22.186L5.2775 22.0881C5.0443 21.8549 5.04303 21.4772 5.27466 21.2425L9.78941 16.6667H3.93301C3.60164 16.6667 3.33301 16.3981 3.33301 16.0667V15.9334C3.33301 15.602 3.60164 15.3334 3.93301 15.3334H9.78941L5.27466 10.7576C5.04303 10.5229 5.0443 10.1452 5.2775 9.91195L5.37541 9.81404C5.60972 9.57972 5.98962 9.57972 6.22394 9.81404L11.7433 15.3334H15.333V11.7436L9.81367 6.2243C9.57936 5.98999 9.57936 5.61009 9.81367 5.37578L9.91158 5.27787C10.1448 5.04466 10.5225 5.0434 10.7573 5.27503L15.333 9.78977V3.93337C15.333 3.602 15.6016 3.33337 15.933 3.33337H16.0663C16.3977 3.33337 16.6663 3.602 16.6663 3.93337V9.78977L21.2421 5.27503C21.4769 5.0434 21.8546 5.04466 22.0878 5.27787L22.1857 5.37578C22.42 5.61009 22.42 5.98999 22.1857 6.2243L16.6663 11.7436V15.3334H20.2561L25.7754 9.81404C26.0097 9.57972 26.3896 9.57972 26.6239 9.81404L26.7218 9.91195C26.9551 10.1452 26.9563 10.5229 26.7247 10.7576L22.2099 15.3334H28.0663C28.3977 15.3334 28.6663 15.602 28.6663 15.9334V16.0667C28.6663 16.3981 28.3977 16.6667 28.0663 16.6667H22.2099L26.7247 21.2425C26.9563 21.4772 26.9551 21.8549 26.7218 22.0881L26.6239 22.186C26.3896 22.4204 26.0097 22.4204 25.7754 22.186L20.2561 16.6667H16.6663V20.2564L22.1857 25.7758C22.42 26.0101 22.42 26.39 22.1857 26.6243L22.0878 26.7222C21.8546 26.9554 21.4769 26.9567 21.2421 26.7251L16.6663 22.2103V28.0667C16.6663 28.3981 16.3977 28.6667 16.0663 28.6667H15.933Z" />
            </g>
        </svg>
    );
}
