import SearchIcon from "@mui/icons-material/Search";
import { Button, Container, IconButton, InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ChangeEvent, ForwardedRef, forwardRef, ReactNode } from "react";

import { useIsBreakpoint, useNextTranslation } from "../hooks";

export type SearchByPhraseLayoutProps = {
    children: ReactNode;
    searchPhrase: string;
    onSearchPhraseChange: (phrase: string) => void;
    placeholder: string;
    isHeaderBackground?: boolean;
    onClose?: () => void;
};

export const SearchByPhraseLayout = forwardRef(function SearchByPhraseLayoutInternal(
    props: SearchByPhraseLayoutProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const [t] = useNextTranslation("general");
    const { children, searchPhrase, onSearchPhraseChange, placeholder, onClose } = props;
    const isSmallScreen = useIsBreakpoint("sm");

    const handleOnSearchPhraseChange = (e: ChangeEvent<HTMLInputElement>) => onSearchPhraseChange(e.target.value);

    return (
        <SearchByPhraseLayoutContainer ref={ref}>
            <Header data-is-header-background={props.isHeaderBackground}>
                <HeaderContent>
                    <InputBox>
                        <IconButton color="inherit">
                            <SearchIcon fontSize={isSmallScreen ? "small" : "medium"} />
                        </IconButton>
                        <StyledTextField
                            autoFocus
                            value={searchPhrase}
                            onChange={handleOnSearchPhraseChange}
                            placeholder={placeholder}
                            disableInjectingGlobalStyles={true}
                        />
                    </InputBox>
                    {onClose && <ButtonCancel onClick={onClose}>{t("cancel")}</ButtonCancel>}
                </HeaderContent>
            </Header>
            <Content>{children}</Content>
        </SearchByPhraseLayoutContainer>
    );
});

const SearchByPhraseLayoutContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
}));

const Header = styled("div")(({ theme }) => ({
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex.appBar,
    '&[data-is-header-background="true"]': {
        background: theme.palette.grey[100],
    },
}));

const HeaderContent = styled(Container)(({ theme }) => ({
    display: "flex",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
}));

const InputBox = styled("div")(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    border: "1px solid #E0E0E0",
    backgroundColor: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    flex: 1,
    color: theme.palette.common.black,
    [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(2),
    },
}));

const StyledTextField = styled(InputBase)(({ theme }) => ({
    color: theme.palette.common.black,
    width: "100%",
}));

const ButtonCancel = styled(Button)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.black,
    padding: 0,
    marginLeft: theme.spacing(1),
    "&:hover": {
        backgroundColor: "unset",
    },
}));

const Content = styled(Container)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));
