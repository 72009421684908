import PanToolAltOutlinedIcon from "@mui/icons-material/PanToolAlt";
import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useRef } from "react";
import { useFormContext } from "react-hook-form";

import { AssetUrl } from "@holibob-packages/vault";

import { ResponsiveImage, Typography } from "../..";
import { QuestionnaireChangeOfHeartClickEvent } from "../../../custom-events";
import { useNextTranslation } from "../../../hooks";
import { Button } from "../../Button";
import { QuestionnaireV2StepProps } from "../../QuestionnaireV2/QuestionnaireV2Provider";
import { RecommendationsQuestionnaireStepContent } from "../RecommendationsQuestionnaireContent";
import { NextButton } from "../RecommendationsQuestionnaireFooter";
import { RecommendationsQuestionnaireHeader } from "../RecommendationsQuestionnaireHeader";

const ContentContainer = styled(RecommendationsQuestionnaireStepContent)(({ theme }) => ({
    gap: theme.spacing(3),
}));

const SubmitButton = styled(NextButton)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const TitleWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
}));

const IMAGE = AssetUrl.fromString("asset://staticImage/questionnaire-success.png");

export function RecommendationsFinalStep({ onClose, onBack, onSubmit }: QuestionnaireV2StepProps) {
    const [t] = useNextTranslation("questionnaire");
    const { formState } = useFormContext();
    const { isSubmitting } = formState;

    return (
        <>
            <RecommendationsQuestionnaireHeader onBack={onBack} onClose={onClose} />
            <ContentContainer>
                <TitleWrapper>
                    <Typography variant="title" size="large" color="subtle" align="center" fontWeight="bold">
                        {t("recommendations.final.title.label")}
                    </Typography>
                    <Typography variant="body" size="large" color="subtle" align="center">
                        {t("recommendations.final.subtitle.label")}
                    </Typography>
                </TitleWrapper>
                <ResponsiveImage src={IMAGE.unwrap()} height={120} width={202} alt={"Welcome"} />
                <SubmitButton
                    onClick={onSubmit}
                    label={t("recommendations.final.seeRecommendations.label")}
                    disabled={isSubmitting}
                    endIcon={isSubmitting && <CircularProgress size={20} color="info" />}
                />
                <UpdateNote />
            </ContentContainer>
        </>
    );
}

const UpdateNote = () => {
    const [t] = useNextTranslation("questionnaire");

    const contentRef = useRef<HTMLDivElement>(null);

    const onClick = () => {
        contentRef.current?.dispatchEvent(new QuestionnaireChangeOfHeartClickEvent());
    };

    return (
        <UpdateNoteWrapper onClick={onClick} ref={contentRef}>
            <Typography variant="headline" size="large" color="subtle" align="center">
                {t("recommendations.final.changeOfHeart.label")}
            </Typography>
            <UpdateNoteContent>
                <Typography>{t("recommendations.final.updateNote.label")}</Typography>
                <div>
                    <UpdateNoteButtonWrapper>
                        <UpdateNoteButton variant="outlined" size="small" style={{ alignSelf: "center" }}>
                            {t("recommendations.final.interests.label")}
                        </UpdateNoteButton>
                        <PointerIcon fontSize="large" />
                    </UpdateNoteButtonWrapper>
                </div>
            </UpdateNoteContent>
        </UpdateNoteWrapper>
    );
};

const UpdateNoteWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    borderRadius: theme.roundness,
    borderStyle: "dashed",
    borderColor: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.main}0A`,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
        width: 290,
    },
}));

const UpdateNoteContent = styled("div")({
    display: "flex",
});

const UpdateNoteButton = styled(Button)(({ theme }) => ({
    transform: "rotate(4.67deg)",
    marginRight: "4px",
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    pointerEvents: "none",
    borderRadius: theme.roundness,
}));

const UpdateNoteButtonWrapper = styled("div")({
    position: "relative",
});

const PointerIcon = styled(PanToolAltOutlinedIcon)(({ theme }) => ({
    "&": {
        fontSize: 35,
        fill: "white",
        stroke: theme.palette.primary.main,
        strokeWidth: 1.5,
    },
    position: "absolute",
    left: "40%",
    bottom: -25,
    transform: "rotate(-13deg)",
}));
