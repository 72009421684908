import BackIconMui from "@mui/icons-material/ArrowBackIosNew";
import CloseIconMui from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

import { useNextTranslation } from "../../hooks/useNextTranslation";
import { Button } from "../Button";
import { Flex } from "../Flex";
import { QuestionnaireV2StepProps } from "../QuestionnaireV2/QuestionnaireV2Provider";
import { QuesitonnaireV2Header } from "../QuestionnaireV2/QuestionnaireV2StepLayout";

const Header = styled(QuesitonnaireV2Header)(({ theme }) => ({
    padding: theme.spacing(3, 3, 1, 3),
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F5F7F9",
}));

const ActionButton = styled(Button)(({ theme }) => ({
    height: 27,
    borderRadius: theme.roundness,
    "&&": { fontSize: 16 },
    "& .MuiButton-endIcon": {
        marginLeft: theme.spacing(0.5),
    },
    "& .MuiButton-startIcon": {
        marginRight: theme.spacing(0.5),
    },
}));

const CloseIcon = styled(CloseIconMui)(({ theme }) => ({
    color: theme.palette.dark.main,
    "&&": { fontSize: 24 },
}));

const BackIcon = styled(BackIconMui)(({ theme }) => ({
    color: theme.palette.dark.main,
    "&&": { fontSize: 24 },
}));

type QuestionnaireHeaderProps = {
    onBack?: QuestionnaireV2StepProps["onBack"];
    onClose: QuestionnaireV2StepProps["onClose"];
};

export function RecommendationsQuestionnaireHeader({ onBack, onClose }: QuestionnaireHeaderProps) {
    const [t] = useNextTranslation("general");
    return (
        <Header>
            <Flex flex={1} start>
                {onBack && (
                    <ActionButton onClick={onBack} startIcon={<BackIcon />} data-color="subtle">
                        {t("back.label")}
                    </ActionButton>
                )}
            </Flex>
            <Flex flex={1} end>
                <ActionButton onClick={onClose} endIcon={<CloseIcon />} data-color="subtle">
                    {t("close.label")}
                </ActionButton>
            </Flex>
        </Header>
    );
}
