import noop from "lodash/noop";
import { useState } from "react";
import { createContext } from "react";

import { ConsumerTripQuestionnaireStep } from "@holibob-packages/consumer-trip-event";

import { useContextHelper } from "../../hooks/useContextHelper";

export type QuestionnaireV2StepProps = Omit<QuestionnaireV2ContextValue, "steps">;

export type QuestionnaireV2Step = {
    id: ConsumerTripQuestionnaireStep;
    Component: React.ComponentType<QuestionnaireV2StepProps>;
    skip?: boolean;
};

type QuestionnaireV2ContextValue = {
    open?: boolean;
    steps: QuestionnaireV2Step[];
    currentStep: number;
    hasNextStep: boolean;
    onNext: () => void;
    onBack: () => void;
    onClose: () => void;
    onSubmit: () => void;
};

const questionnaireV2ContextDefaultValue = {
    open: false,
    steps: [],
    currentStep: 0,
    hasNextStep: false,
    onNext: noop,
    onBack: noop,
    onClose: noop,
    onSubmit: noop,
};

const QuestionnaireV2Context = createContext<QuestionnaireV2ContextValue>(questionnaireV2ContextDefaultValue);

export type QuestionnaireV2ProviderProps = {
    children: React.ReactNode;
    onNext?: (props: { currentStep: number; onNext: () => void }) => void;
    onBack?: (props: { currentStep: number; onBack: () => void }) => void;
    onClose: (currentStep: number) => void;
} & Pick<QuestionnaireV2ContextValue, "open" | "steps" | "onSubmit">;

export const QuestionnaireV2Provider = (props: QuestionnaireV2ProviderProps) => {
    const {
        open,
        onNext: onNextAction,
        onBack: onBackAction,
        onClose: onCloseAction,
        onSubmit,
        steps,
        children,
    } = props;
    const [currentStep, setCurrentStep] = useState(0);
    const hasNextStep = !steps[currentStep + 1]?.skip;
    const hasPreviousStelStep = !!steps[currentStep - 1] as boolean;
    const goToNextStep = () => {
        if (hasNextStep) setCurrentStep((currentStep) => currentStep + 1);
    };

    const goToPrevStep = () => {
        if (hasPreviousStelStep) setCurrentStep((currentStep) => currentStep - 1);
    };

    const onNext = () => {
        if (onNextAction) {
            onNextAction({ currentStep, onNext: goToNextStep });
        } else {
            goToNextStep();
        }
    };

    const onBack = () => {
        if (onBackAction) {
            onBackAction({ currentStep, onBack: goToPrevStep });
        } else {
            goToPrevStep();
        }
    };

    const onClose = () => onCloseAction(currentStep);

    return (
        <QuestionnaireV2Context.Provider
            value={{ open, steps, currentStep, hasNextStep, onNext, onBack, onClose, onSubmit }}
        >
            {children}
        </QuestionnaireV2Context.Provider>
    );
};

export function useQuestionnaireV2Context() {
    return useContextHelper(QuestionnaireV2Context);
}
