import { ComponentProps } from "react";

export function WindWeather(props: ComponentProps<"svg">) {
    return (
        <svg {...props} width="32" height="32" viewBox="0 0 32 32">
            <g>
                <path d="M15.333 25.6919C14.5296 25.6919 13.8287 25.4355 13.2304 24.9227C12.8277 24.5775 12.5257 24.1782 12.3244 23.7246C12.1703 23.3775 12.4577 23.0253 12.8374 23.0253H13.0597C13.3154 23.0253 13.5349 23.1927 13.6661 23.4122C13.7995 23.6354 13.9744 23.8274 14.1907 23.9881C14.5232 24.2351 14.9039 24.3586 15.333 24.3586C15.8817 24.3586 16.3522 24.1624 16.7445 23.7701C17.1369 23.3778 17.333 22.9073 17.333 22.3586C17.333 21.8099 17.1369 21.3394 16.7445 20.9471C16.3522 20.5548 15.8817 20.3586 15.333 20.3586H3.33301V19.0253H15.333C16.2561 19.0253 17.0424 19.3501 17.692 19.9996C18.3416 20.6492 18.6663 21.4355 18.6663 22.3586C18.6663 23.2817 18.3416 24.068 17.692 24.7176C17.0424 25.3672 16.2561 25.6919 15.333 25.6919ZM3.33301 12.974V11.6407H20.6663C21.4151 11.6407 22.0467 11.3834 22.5612 10.8689C23.0757 10.3543 23.333 9.72271 23.333 8.974C23.333 8.22527 23.0757 7.59364 22.5612 7.07913C22.0467 6.5646 21.4151 6.30733 20.6663 6.30733C20.0202 6.30733 19.4655 6.49622 19.0022 6.874C18.6653 7.1488 18.4147 7.4765 18.2505 7.85713C18.1403 8.1125 17.9058 8.30733 17.6277 8.30733H17.4204C17.0515 8.30733 16.7661 7.97495 16.8834 7.62522C17.1076 6.95709 17.4981 6.38659 18.0548 5.91373C18.7924 5.28724 19.6629 4.974 20.6663 4.974C21.7894 4.974 22.7369 5.35989 23.5086 6.13167C24.2804 6.90346 24.6663 7.85091 24.6663 8.974C24.6663 10.0971 24.2804 11.0445 23.5086 11.8163C22.7369 12.5881 21.7894 12.974 20.6663 12.974H3.33301ZM26.0151 23.1159C25.6654 23.2332 25.333 22.9478 25.333 22.5789V22.3716C25.333 22.0935 25.5278 21.859 25.7832 21.7488C26.1638 21.5846 26.4915 21.334 26.7663 20.9971C27.1441 20.5338 27.333 19.9791 27.333 19.333C27.333 18.5843 27.0757 17.9526 26.5612 17.4381C26.0467 16.9236 25.4151 16.6663 24.6663 16.6663H3.33301V15.333H24.6663C25.7894 15.333 26.7369 15.7189 27.5086 16.4907C28.2804 17.2624 28.6663 18.2099 28.6663 19.333C28.6663 20.3364 28.3531 21.2069 27.7266 21.9445C27.2537 22.5012 26.6832 22.8917 26.0151 23.1159Z" />
            </g>
        </svg>
    );
}
