import { styled } from "@mui/material/styles";

export const QuesitonnaireV2Header = styled("header")({
    flexGrow: 0,
    flexShrink: 0,
    position: "sticky",
    top: 0,
    zIndex: 1,
});

export const QuestionnaireV2Content = styled("div")({
    overflow: "unset",
    flexGrow: 1,
});

export const QuesitonnaireV2Footer = styled("footer")({
    flexGrow: 0,
    flexShrink: 0,
    position: "sticky",
    bottom: 0,
});
