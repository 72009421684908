import { styled } from "@mui/material/styles";
import { useImperativeHandle, useMemo, forwardRef } from "react";
import React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import {
    QuestionnaireNextClickEvent,
    QuestionnaireBackClickEvent,
    QuestionnaireCloseEvent,
    QuestionnaireSubmitEvent,
} from "../../custom-events";
import { QuestionnaireV2Container } from "../QuestionnaireV2/QuestionnaireV2Container";
import { QuestionnaireV2ProviderProps, QuestionnaireV2Step } from "../QuestionnaireV2/QuestionnaireV2Provider";
import { RecommendationsFinalStep } from "./steps/RecommendationsFinalStep";
import {
    ConsumerTripInterestTagId,
    RecommendationsInterestsStep,
    consumerTripInterestTagIdList,
} from "./steps/RecommendationsInterestsStep";

// TODO: QuestionnaireV2 part 2
// import { RecommendationsLandingStep } from "./steps/RecommendationsLandingStep";
// import { RecommendationsActiveStep } from "./steps/RecommendationsActiveStep";
// import { RecommendationsBudgetStep } from "./steps/RecommendationsBudgetStep";
// import { RecommendationsGroupSizeStep } from "./steps/RecommendationsGroupSizeStep";

const RecommendationsQuestionnaireContainer = styled(QuestionnaireV2Container)({
    backgroundColor: "#F5F7F9",
});

export type RecommendationsQuestionnaireFormInputs = {
    consumerTripId: string;
    consumerFirstName: string;
    interestList: { id: ConsumerTripInterestTagId; value: string }[];
};

type RecommendationsQuestionnaireProps = {
    open?: boolean;
    consumerTripId: string;
    consumerFirstName?: string;
    consumerTripSystemTagIdList: string[];
    onClose: () => void;
    onSubmit: SubmitHandler<RecommendationsQuestionnaireFormInputs>;
};

export const RecommendationsQuestionnaire = forwardRef(function RecommendationsQuestionnaireInternal(
    props: RecommendationsQuestionnaireProps,
    ref
) {
    const {
        consumerTripId,
        consumerFirstName,
        open,
        onClose: onCloseAction,
        onSubmit,
        consumerTripSystemTagIdList,
    } = props;
    const initialInterestList = mapConsumerTripSystemTagIdListToInterest(consumerTripSystemTagIdList);
    const formMethods = useForm<RecommendationsQuestionnaireFormInputs>({
        defaultValues: { consumerTripId, consumerFirstName, interestList: initialInterestList },
    });
    const { handleSubmit } = formMethods;
    const contentRef = React.useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => contentRef.current!);

    const onQuestionnaireSubmit = () => {
        contentRef.current?.dispatchEvent(new QuestionnaireSubmitEvent());
        void handleSubmit(onSubmit)();
    };

    const steps: QuestionnaireV2Step[] = useMemo(() => {
        return [
            {
                id: "interests",
                Component: RecommendationsInterestsStep,
            },
            {
                id: "final",
                Component: RecommendationsFinalStep,
                skip: !initialInterestList,
            },
        ];
    }, [initialInterestList]);

    const onNext: QuestionnaireV2ProviderProps["onNext"] = ({ currentStep, onNext }) => {
        const questionnaireStep = steps[currentStep].id;
        contentRef.current?.dispatchEvent(new QuestionnaireNextClickEvent({ questionnaireStep }));
        onNext();
    };

    const onBack: QuestionnaireV2ProviderProps["onBack"] = ({ currentStep, onBack }) => {
        const questionnaireStep = steps[currentStep].id;
        contentRef.current?.dispatchEvent(new QuestionnaireBackClickEvent({ questionnaireStep }));
        onBack();
    };
    const onClose: QuestionnaireV2ProviderProps["onClose"] = (currentStep) => {
        const questionnaireStep = steps[currentStep].id;
        contentRef.current?.dispatchEvent(new QuestionnaireCloseEvent({ questionnaireStep }));
        onCloseAction();
    };

    if (!open) return false;

    return (
        <FormProvider {...formMethods}>
            <RecommendationsQuestionnaireContainer
                steps={steps}
                open={open}
                onClose={onClose}
                onSubmit={onQuestionnaireSubmit}
                onNext={onNext}
                onBack={onBack}
                contentRef={contentRef}
            />
        </FormProvider>
    );
});

const mapConsumerTripSystemTagIdListToInterest = (
    consumerTripSystemTagIdList: string[]
): RecommendationsQuestionnaireFormInputs["interestList"] => {
    const interestList = consumerTripInterestTagIdList
        .filter((interestSystemTagId) => consumerTripSystemTagIdList.includes(interestSystemTagId))
        .map((interestSystemTagId) => ({
            id: interestSystemTagId as ConsumerTripInterestTagId,
            value: interestSystemTagId,
        }));

    return interestList;
};
