import { styled } from "@mui/material/styles";
import { ForwardedRef, forwardRef } from "react";

import { HeroImagesTypographyProps } from "../HeroImages";

export const ConsumerTripHeroImagesTitle = forwardRef(function ConsumerTripHeroImagesTitle(
    props: HeroImagesTypographyProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    if (typeof props.children !== "string") {
        return (
            <TitleWrapper ref={ref}>
                <p>{props.children}</p>
            </TitleWrapper>
        );
    }

    // TODO: This may not work in all cases and should consider a more robust solution, where we split and bold words in extranet

    const words = props.children.split(" ");
    const lastWord = words.length > 1 ? words.pop() ?? "" : "";
    const firstWords = words.join(" ");

    return (
        <TitleWrapper ref={ref} data-is-multi-line={firstWords.length > 1}>
            <p>{firstWords}</p>

            {!!lastWord && <p>{lastWord}</p>}
        </TitleWrapper>
    );
});

export const ConsumerTripHeroImagesDescription = forwardRef(function ConsumerTripHeroImagesDescription(
    props: HeroImagesTypographyProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    return <StyledDescriptionTypography {...props} ref={ref} />;
});

const TitleWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",

    '&[data-is-multi-line="false"] p:not(:last-child)::after': {
        content: '" "',
        whiteSpace: "pre",
    },

    '&[data-is-multi-line="true"]': {
        flexDirection: "column",

        "& p": {
            "&:nth-of-type(odd)": {
                marginInlineEnd: theme.spacing(2),
            },

            "&:nth-of-type(even)": {
                fontWeight: 400,
                marginInlineStart: theme.spacing(2),
            },
        },
    },

    "& p": {
        color: theme.palette.common.white,
        margin: 0,
        textShadow: "0px 2px 2px rgba(0, 0, 0, 0.14)",
        fontSize: 50,
        lineHeight: 0.8,
        letterSpacing: 1.5,
        fontWeight: 700,

        [theme.breakpoints.up("md")]: {
            letterSpacing: 2.04,
            lineHeight: 0.9,
            fontSize: 68,
        },
    },
}));

const StyledDescriptionTypography = styled("p")(({ theme }) => ({
    color: theme.palette.common.white,
    margin: 0,
    textShadow: "0px 1px 1px rgba(0, 0, 0, 0.20)",
    fontWeight: 500,
    fontSize: 16,

    [theme.breakpoints.up("md")]: {
        fontSize: 20,
        lineHeight: 0.8,
    },
}));
