import { Dialog, DialogProps, Slide } from "@mui/material";
import React, { ReactNode } from "react";

export type SearchModalProps = {
    children: ReactNode;
    showDialog?: boolean;
    open?: boolean;
} & Omit<DialogProps, "open">;

export const SearchModal = (props: SearchModalProps) => {
    const { children, open = false } = props;

    return (
        <Dialog fullScreen open={open} TransitionComponent={Slide} transitionDuration={500} disableRestoreFocus>
            {children}
        </Dialog>
    );
};
