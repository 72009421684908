type countryGreetingMapper = Record<string, string | undefined>;
export const countryGreetingMapper: countryGreetingMapper = {
    france: "Bonjour",
    ireland: "Hello",
    italy: "Ciao",
    portugal: "Olá",
    romania: "Bună",
    poland: "Cześć",
    singapore: "Hello",
    spain: "Hola",
    turkey: "Merhaba",
    "united kingdom": "Hello",
    "abu dhabi": "Marhaba",
    malta: "Hello",
    "united states of america": "Hello",
    USA: "Hello",
    malaysia: "Salaam",
    thailand: "Hello",
    greece: "Yassou",
    sweden: "Hej",
    netherlands: "Hallo",
    germany: "Hallo",
    "united arab emirates": "Marhaba",
    UAE: "Marhaba",
    mexico: "Hola",
    "czech republic": "Ahoj",
    austria: "Hallo",
    vietnam: "Xin chào",
    hungary: "Szia",
    belgium: "Hoi",
    bogota: "Hola",
    medellin: "Hola",
    vancouver: "Hello",
    sofia: "Zdravey",
    "new delhi": "Namaste",
    porto: "Olá",
    "hong kong": "Néih hóu",
    amman: "Marhaba",
    valencia: "Hola",
    riga: "Sveiki",
    mumbai: "Namaste",
    seoul: "Annyeong",
    "san juan": "Hola",
    toronto: "Hello",
};
