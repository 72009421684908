import { ComponentProps } from "react";

export function CloudyWeather(props: ComponentProps<"svg">) {
    return (
        <svg {...props} width="32" height="32" viewBox="0 0 32 32">
            <g>
                <path d="M8.66699 24C7.00375 24 5.58794 23.4196 4.41956 22.2589C3.25118 21.0983 2.66699 19.6863 2.66699 18.023C2.66699 16.4948 3.18921 15.1384 4.23366 13.9538C5.2781 12.7692 6.57639 12.1333 8.12853 12.0461C8.42768 10.1042 9.32085 8.49998 10.808 7.23331C12.2952 5.96665 14.026 5.33331 16.0003 5.33331C18.2243 5.33331 20.1136 6.11066 21.6683 7.66535C23.223 9.22006 24.0003 11.1094 24.0003 13.3333V14.6666H24.8208C26.0978 14.7077 27.1691 15.1765 28.035 16.073C28.9008 16.9696 29.3337 18.0564 29.3337 19.3333C29.3337 20.641 28.8828 21.7457 27.9811 22.6474C27.0794 23.5491 25.9747 24 24.667 24H8.66699ZM8.66699 22.6666H24.667C25.6003 22.6666 26.3892 22.3444 27.0337 21.7C27.6781 21.0555 28.0003 20.2666 28.0003 19.3333C28.0003 18.4 27.6781 17.6111 27.0337 16.9666C26.3892 16.3222 25.6003 16 24.667 16H22.667V13.3333C22.667 11.4889 22.017 9.91665 20.717 8.61665C19.417 7.31665 17.8448 6.66665 16.0003 6.66665C14.1559 6.66665 12.5837 7.31665 11.2837 8.61665C9.98366 9.91665 9.33366 11.4889 9.33366 13.3333H8.66699C7.3781 13.3333 6.2781 13.7889 5.36699 14.7C4.45588 15.6111 4.00033 16.7111 4.00033 18C4.00033 19.2889 4.45588 20.3889 5.36699 21.3C6.2781 22.2111 7.3781 22.6666 8.66699 22.6666Z" />
            </g>
        </svg>
    );
}
