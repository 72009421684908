"use client";

import React from "react";
import { MarkRequired } from "ts-essentials";

import { ProductSearchByPhraseLayout, ProductSearchByPhraseLayoutProps } from "./ProductSearchByPhraseLayout";
import { SearchModal } from "./SearchModal";

export type ProductSearchModalProps = MarkRequired<ProductSearchByPhraseLayoutProps, "productHrefFactory"> & {
    isOpen?: boolean;
    contentRef: React.RefObject<HTMLDivElement>;
    onSearch?: (phrase: string) => void;
};

export const ProductSearchModal = ({ isOpen, contentRef, ...props }: ProductSearchModalProps) => {
    return (
        <SearchModal open={isOpen}>
            <ProductSearchByPhraseLayout ref={contentRef} isHeaderBackground={true} {...props} />
        </SearchModal>
    );
};
