import { ComponentProps } from "react";

export function SleetWeather(props: ComponentProps<"svg">) {
    return (
        <svg {...props} width="32" height="32" viewBox="0 0 32 32">
            <g>
                <g>
                    <path d="M8.06774 24.7692C7.772 24.7692 7.51431 24.6594 7.29467 24.4397C7.075 24.2201 6.96517 23.9624 6.96517 23.6667C6.96517 23.3709 7.075 23.1132 7.29467 22.8936C7.51431 22.6739 7.772 22.5641 8.06774 22.5641C8.36347 22.5641 8.62116 22.6739 8.8408 22.8936C9.06047 23.1132 9.1703 23.3709 9.1703 23.6667C9.1703 23.9624 9.06047 24.2201 8.8408 24.4397C8.62116 24.6594 8.36347 24.7692 8.06774 24.7692ZM10.01 28.7692C9.7143 28.7692 9.45661 28.6594 9.23697 28.4397C9.0173 28.2201 8.90747 27.9624 8.90747 27.6667C8.90747 27.3709 9.0173 27.1132 9.23697 26.8936C9.45661 26.6739 9.7143 26.5641 10.01 26.5641C10.3058 26.5641 10.5635 26.6739 10.7831 26.8936C11.0028 27.1132 11.1126 27.3709 11.1126 27.6667C11.1126 27.9624 11.0028 28.2201 10.7831 28.4397C10.5635 28.6594 10.3058 28.7692 10.01 28.7692ZM19.769 24.7692C19.4733 24.7692 19.2156 24.6594 18.996 24.4397C18.7763 24.2201 18.6665 23.9624 18.6665 23.6667C18.6665 23.3709 18.7763 23.1132 18.996 22.8936C19.2156 22.6739 19.4733 22.5641 19.769 22.5641C20.0648 22.5641 20.3225 22.6739 20.5421 22.8936C20.7618 23.1132 20.8716 23.3709 20.8716 23.6667C20.8716 23.9624 20.7618 24.2201 20.5421 24.4397C20.3225 24.6594 20.0648 24.7692 19.769 24.7692ZM21.7113 28.7692C21.4156 28.7692 21.1579 28.6594 20.9383 28.4397C20.7186 28.2201 20.6088 27.9624 20.6088 27.6667C20.6088 27.3709 20.7186 27.1132 20.9383 26.8936C21.1579 26.6739 21.4156 26.5641 21.7113 26.5641C22.0071 26.5641 22.2648 26.6739 22.4844 26.8936C22.7041 27.1132 22.8139 27.3709 22.8139 27.6667C22.8139 27.9624 22.7041 28.2201 22.4844 28.4397C22.2648 28.6594 22.0071 28.7692 21.7113 28.7692Z" />
                    <path d="M15.8126 28.8608C15.9861 28.9232 16.1541 28.9082 16.3165 28.8159C16.4788 28.7236 16.5955 28.5907 16.6665 28.4172C16.7374 28.2437 16.7267 28.0757 16.6344 27.9134L14.378 23.4262C14.2857 23.2638 14.1485 23.1471 13.9665 23.0762C13.7844 23.0052 13.6122 23.0159 13.4498 23.1082C13.2874 23.2005 13.175 23.3334 13.1126 23.5069C13.0502 23.6804 13.0652 23.8484 13.1575 24.0108L15.4139 28.5236C15.5062 28.686 15.6391 28.7984 15.8126 28.8608Z" />
                    <path d="M27.2421 28.8608C27.4156 28.9232 27.5836 28.9082 27.746 28.8159C27.9084 28.7236 28.025 28.5907 28.096 28.4172C28.1669 28.2437 28.1562 28.0757 28.0639 27.9134L25.8075 23.4262C25.7152 23.2638 25.578 23.1471 25.396 23.0762C25.2139 23.0052 25.0417 23.0159 24.8793 23.1082C24.7169 23.2005 24.6045 23.3334 24.5421 23.5069C24.4797 23.6804 24.4947 23.8484 24.587 24.0108L26.8434 28.5236C26.9357 28.686 27.0686 28.7984 27.2421 28.8608Z" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.99967 20.667C8.14839 20.667 6.57445 20.0187 5.27787 18.7221C3.9813 17.4255 3.33301 15.8516 3.33301 14.0003C3.33301 12.2755 3.91421 10.7841 5.07661 9.52592C6.23899 8.26781 7.69454 7.54216 9.44327 7.34899C10.1031 6.09943 11.0069 5.11781 12.1548 4.40412C13.3027 3.69045 14.5843 3.33362 15.9997 3.33362C17.9142 3.33362 19.5591 3.96823 20.9343 5.23745C22.3095 6.5067 23.1099 8.08832 23.3356 9.98232C24.9715 10.0815 26.2689 10.6507 27.2279 11.69C28.1869 12.7293 28.6663 13.9439 28.6663 15.3336C28.6663 16.8123 28.1471 18.0708 27.1086 19.1093C26.0702 20.1477 24.8117 20.667 23.333 20.667H9.99967ZM23.333 19.3336H9.99967C8.52276 19.3336 7.26464 18.814 6.22531 17.7747C5.186 16.7353 4.66634 15.4772 4.66634 14.0003C4.66634 12.5456 5.18044 11.3041 6.20864 10.2759C7.23686 9.24772 8.47832 8.7114 9.93301 8.66695H10.3304L10.4843 8.27722C10.98 7.17977 11.7223 6.30371 12.7112 5.64902C13.7001 4.99431 14.7963 4.66695 15.9997 4.66695C17.6544 4.66695 19.068 5.25327 20.2407 6.42592C21.4134 7.59858 21.9997 9.01226 21.9997 10.667V11.3336H23.333C24.4373 11.3336 25.38 11.7242 26.1612 12.5054C26.9424 13.2866 27.333 14.2294 27.333 15.3336C27.333 16.4379 26.9424 17.3806 26.1612 18.1618C25.38 18.943 24.4373 19.3336 23.333 19.3336Z"
                    />
                </g>
            </g>
        </svg>
    );
}
