"use client";

import { styled, keyframes, useTheme } from "@mui/material/styles";
import { ComponentProps, ComponentType, HTMLAttributes } from "react";

import { TopAttractionsStory } from "../../icons/Story/TopAttractions";
import { CSSVariable } from "../../utils/CSSVariable";

const STORY_ICON_SIZE = new CSSVariable("--story-icon-size");
const STORY_ICON_LOADER_CIRCUMFERENCE = new CSSVariable("--story-icon-loader-circumference");
const STORY_ICON_ANIMATION_DURATION = new CSSVariable("--story-icon-animation-duration");
const STORY_ICON_ANIMATION_PLAY_STATE = new CSSVariable("--story-icon-animation-play-state");
const STORY_ICON_ACTIVE_COLOR = new CSSVariable("--story-icon-active-color");
const STORY_ICON_INACTIVE_COLOR = new CSSVariable("--story-icon-inactive-color");

const STORY_ICON_CLICK_ANIMATION_DURATION = "0.5s";

export type StoryProps = {
    size?: number;
    durationInSeconds?: number;
    isActive: boolean;
    isPaused?: boolean;
    isLoading?: boolean;
    reset?: boolean;
    IconComponent?: ComponentType<ComponentProps<"svg">>;
    onFinish?: () => void;
    onStart?: () => void;
} & HTMLAttributes<HTMLDivElement>;

export function StoryButton({
    size = 50,
    durationInSeconds = 10,
    isActive = true,
    isPaused = false,
    isLoading = false,
    reset = false,
    IconComponent = TopAttractionsStory,
    onFinish,
    onStart,
    style,
    ...restProps
}: StoryProps) {
    const circleSize = size / 2;
    const radius = circleSize - 1;
    const circumference = 2 * 3.14 * radius;
    const strokeWidth = 2;
    const theme = useTheme();

    const animationCssVariables = {
        [STORY_ICON_SIZE.toString()]: `${size}px`,
        [STORY_ICON_LOADER_CIRCUMFERENCE.toString()]: circumference,
        [STORY_ICON_ANIMATION_DURATION.toString()]: `${durationInSeconds}s`,
        [STORY_ICON_ANIMATION_PLAY_STATE.toString()]: isPaused ? "paused" : "running",
        [STORY_ICON_ACTIVE_COLOR.toString()]: theme.palette.common.white,
        [STORY_ICON_INACTIVE_COLOR.toString()]: theme.palette.grey[500],
    };

    return (
        <Container style={{ ...style, ...animationCssVariables }} {...restProps}>
            <svg
                width={size}
                height={size}
                viewBox={`0 0 ${size} ${size}`}
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                style={{ transform: "rotate(-90deg)" }}
            >
                <circle
                    r={radius}
                    cx={circleSize}
                    cy={circleSize}
                    fill="rgb(256,256,256, 0.1)"
                    stroke={theme.palette.grey[700]}
                    strokeWidth={strokeWidth}
                ></circle>

                {isLoading ? (
                    <Loader
                        r={radius - strokeWidth}
                        cx={circleSize}
                        cy={circleSize}
                        fill="none"
                        stroke={theme.palette.common.white}
                        strokeWidth={strokeWidth}
                        strokeDasharray={`${circumference * 0.25}px ${circumference * 0.75}px`}
                        data-is-loading={isLoading}
                    ></Loader>
                ) : (
                    <StoryProgress
                        r={radius}
                        cx={circleSize}
                        cy={circleSize}
                        stroke={theme.palette.common.white}
                        strokeWidth={strokeWidth}
                        strokeDashoffset={`${circumference}px`}
                        fill="transparent"
                        strokeDasharray={`${circumference}px`}
                        onAnimationEnd={onFinish}
                        onAnimationStart={onStart}
                        data-is-active={isActive}
                        data-reset={reset}
                    ></StoryProgress>
                )}
            </svg>
            <IconContainer data-is-active={isActive} data-reset={reset}>
                <IconComponent />
            </IconContainer>
        </Container>
    );
}

const progressAnimationWithDelay = keyframes`
    0%, 10% {
        stroke-dashoffset: ${STORY_ICON_LOADER_CIRCUMFERENCE.reference};
    }
    90%, 100% {
        stroke-dashoffset: 0;
    }
`;

const loadingAnimation = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const fadeAnimationWithDelay = keyframes`
    0%, 10% {
        opacity: 0;
    }
    15%, 85% {
        opacity: 1;
    }
    90%, 100% {
        opacity: 0;
    }
`;

const fadeAnimation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const colorAnimation = keyframes`
    0%, 10% {
        color: ${STORY_ICON_INACTIVE_COLOR.reference};
    }
    15%, 85% {
        color: ${STORY_ICON_ACTIVE_COLOR.reference};
    }
    90%, 100% {
        color: ${STORY_ICON_INACTIVE_COLOR.reference};
    }
`;

const Container = styled("div")({
    position: "relative",
    width: STORY_ICON_SIZE.reference,
    height: STORY_ICON_SIZE.reference,
});

const IconContainer = styled("div")({
    position: "absolute",
    left: 0,
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: STORY_ICON_INACTIVE_COLOR.reference,
    transition: `color ${STORY_ICON_CLICK_ANIMATION_DURATION}`,
    "& svg": {
        fill: "currentColor",
    },
    '&[data-is-active="true"][data-reset="true"]': {
        color: STORY_ICON_ACTIVE_COLOR.reference,
        animationName: "none",
    },
    '&[data-is-active="true"]': {
        animationName: colorAnimation,
        animationDuration: STORY_ICON_ANIMATION_DURATION.reference,
        animationIterationCount: "infinite",
        animationTimingFunction: "ease-in-out",
        animationFillMode: "forwards",
        animationPlayState: STORY_ICON_ANIMATION_PLAY_STATE.reference,
    },
    width: STORY_ICON_SIZE.reference,
    height: STORY_ICON_SIZE.reference,
});

const StoryProgress = styled("circle")({
    "&[data-is-active='true'][data-reset='true']": {
        animation: `${fadeAnimation} ${STORY_ICON_CLICK_ANIMATION_DURATION}`,
        strokeDashoffset: 0,
    },
    "&[data-is-active='true']": {
        strokeDasharray: STORY_ICON_LOADER_CIRCUMFERENCE.reference,
        animation: [
            `${progressAnimationWithDelay} calc(${STORY_ICON_ANIMATION_DURATION.reference} - 1.2s) linear 0.6s normal forwards ${STORY_ICON_ANIMATION_PLAY_STATE.reference}`,
            `${fadeAnimationWithDelay} ${STORY_ICON_ANIMATION_DURATION.reference} ease-in-out 0s normal forwards ${STORY_ICON_ANIMATION_PLAY_STATE.reference}`,
        ].join(", "),
        animationPlayState: STORY_ICON_ANIMATION_PLAY_STATE.reference,
    },
});
const Loader = styled("circle")({
    "&[data-is-loading='true']": {
        animationDuration: "3s",
        animationName: loadingAnimation,
        animationIterationCount: "infinite",
        animationTimingFunction: "linear",
        transformOrigin: "center center",
    },
});
