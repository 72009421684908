import { ComponentType, ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { ConsumerTripWeatherForecastFragment } from "../../gql-request";
import { ProductCardV2, ProductCardV2Props } from "../../product";
import { ConsumerTripContainer } from "../ConsumerTrip/ConsumerTripContainer";
import { HbmlCurationProductsProductHrefFactory } from "./HbmlCurationProductsV2Node";
import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export type HbmlConsumerTripNodeAttributes = {
    hero?: {
        title?: string;
        images?: string[];
    };
    curationId?: string;
};

export type HeroImageConsumerTrip = {
    consumerId: string;
    arrivalDate: string | null;
    departureDate: string | null;
    destinationCountryName: string | null;
    destinationPagePath: string | null;
    consumerTripId: string;
    consumer: { givenName: string | null } | null;
    weatherForecast: ConsumerTripWeatherForecastFragment[] | null;
};

export type ItemList = {
    id: string;
    name: string;
};

export type HbmlConsumerTripProps = {
    productHrefFactory: HbmlCurationProductsProductHrefFactory;
    ProductCardComponent?: ComponentType<ProductCardV2Props>;
    pagePath: string;
    placeId?: string | null;
    consumerTripId: string;
    heroTitle?: string | null;
    curationId?: string;
};

export const HbmlConsumerTripNode = hbmlNodeFactory<
    typeof HbmlElements.CONSUMER_TRIP,
    HbmlConsumerTripNodeAttributes,
    HbmlConsumerTripProps & Omit<HTMLAttributes<HTMLDivElement>, "content">
>({
    type: HbmlElements.CONSUMER_TRIP,
    Component: forwardRef(function HbmlConsumerTripNodeInternal(props, ref: ForwardedRef<HTMLDivElement>) {
        const { node, placeId, curationId, ProductCardComponent = ProductCardV2 } = props;
        const heroTitle = props.node.hero?.title ?? props.heroTitle;
        const heroImageAssetUrlList = props.node.hero?.images;

        return (
            <ConsumerTripContainer
                ProductCardComponent={ProductCardComponent}
                placeId={placeId}
                heroImageAssetUrlList={heroImageAssetUrlList}
                heroTitle={heroTitle}
                curationId={curationId}
                {...node}
                {...props}
                ref={ref}
            />
        );
    }),
});
