import { useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";

import { ConsumerTripContentItemProductListType } from "@holibob-packages/graphql-types";

import { deepObjectWithoutTypename } from "../utils";
import { ConsumerTripPersonalizationShelf } from "../utils/storiesHelper";
import {
    ConsumerTripContentItemProductListItemFragment,
    ConsumerTripProductFragment,
    ConsumerTripProductListDocument,
    ConsumerTripProductListQuery,
} from "./graphql";

export type ConsumerTripProductList = ConsumerTripProductFragment[];

export type ConsumerTripProductsQueryProps = {
    personalizationShelfList: ConsumerTripPersonalizationShelf[];
};

export type ConsumerTripProductsQueryResult = Partial<
    Record<ConsumerTripContentItemProductListType, ConsumerTripProductsQueryResultEntry>
>;

export type ConsumerTripProductsQueryResultEntry =
    | { loading: true }
    | { loading: false; data: ConsumerTripProductList };

const PAGE_SIZE = 20;

export function useConsumerTripProductsQuery({
    personalizationShelfList,
}: ConsumerTripProductsQueryProps): ConsumerTripProductsQueryResult {
    const [products, setProducts] = useState<ConsumerTripProductsQueryResult>(() => {
        return Object.fromEntries(personalizationShelfList.map((rule) => [rule.type, { loading: true }]));
    });

    const client = useApolloClient();

    useEffect(() => {
        for (const shelf of personalizationShelfList) {
            getProductsLoaderForShelf({ shelf, client })
                .then(({ productList }) => {
                    setProducts((prev) => ({
                        ...prev,
                        [shelf.type]: { data: filterLoadedProductList(productList?.nodes ?? []), loading: false },
                    }));
                })
                .catch(() => {
                    setProducts((prev) => ({
                        ...prev,
                        [shelf.type]: { data: [], loading: false },
                    }));
                });
        }
    }, [personalizationShelfList, setProducts, client]);

    return products;
}

type ProductsLoaderForShelfArgs = {
    shelf: ConsumerTripContentItemProductListItemFragment;
    client: ReturnType<typeof useApolloClient>;
};
function getProductsLoaderForShelf({ shelf, client }: ProductsLoaderForShelfArgs) {
    if (shelf.type === "BUSINESS") {
        return productsLoaderForBusinessShelf({ shelf, client });
    }

    return productsLoaderForRegularShelf({ shelf, client });
}

async function productsLoaderForRegularShelf({ shelf, client }: ProductsLoaderForShelfArgs) {
    const filterWithoutTypeName = deepObjectWithoutTypename(shelf.filter);
    const result = await client.query<ConsumerTripProductListQuery>({
        query: ConsumerTripProductListDocument,
        variables: {
            filter: filterWithoutTypeName,
            pageSize: PAGE_SIZE,
        },
    });

    return result.data;
}

async function productsLoaderForBusinessShelf({
    shelf,
    client,
}: {
    shelf: ConsumerTripContentItemProductListItemFragment;
    client: ReturnType<typeof useApolloClient>;
}) {
    const filterWithoutTypeName = deepObjectWithoutTypename(shelf.filter);
    const filterWithoutStarTime = { ...filterWithoutTypeName, startTime: null };
    const [firstQueryResult, secondQuery] = await Promise.all([
        client.query<ConsumerTripProductListQuery>({
            query: ConsumerTripProductListDocument,
            variables: {
                filter: filterWithoutTypeName,
                pageSize: PAGE_SIZE,
            },
        }),
        client.query<ConsumerTripProductListQuery>({
            query: ConsumerTripProductListDocument,
            variables: {
                filter: filterWithoutStarTime,
                pageSize: PAGE_SIZE,
            },
        }),
    ]);

    if (firstQueryResult.data.productList?.nodes.length) {
        return firstQueryResult.data;
    }

    if (secondQuery.data.productList?.nodes.length) {
        return secondQuery.data;
    }

    return { productList: null } as ConsumerTripProductListQuery;
}

function filterLoadedProductList(productList: ConsumerTripProductList): ConsumerTripProductList {
    if (productList.length < 2) {
        return [];
    }

    return productList;
}
