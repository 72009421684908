import { ComponentProps } from "react";

export function FogWeather(props: ComponentProps<"svg">) {
    return (
        <svg {...props} width="32" height="32" viewBox="0 0 32 32">
            <g>
                <g>
                    <path d="M8.66634 25.3333C8.47745 25.3333 8.31912 25.2693 8.19134 25.1414C8.06356 25.0134 7.99967 24.8549 7.99967 24.6657C7.99967 24.4766 8.06356 24.3183 8.19134 24.191C8.31912 24.0637 8.47745 24 8.66634 24H20.6663C20.8552 24 21.0136 24.064 21.1413 24.1919C21.2691 24.3198 21.333 24.4784 21.333 24.6675C21.333 24.8567 21.2691 25.0149 21.1413 25.1423C21.0136 25.2696 20.8552 25.3333 20.6663 25.3333H8.66634ZM14.6663 28.6666C14.4775 28.6666 14.3191 28.6027 14.1913 28.4747C14.0636 28.3468 13.9997 28.1882 13.9997 27.9991C13.9997 27.8099 14.0636 27.6517 14.1913 27.5243C14.3191 27.397 14.4775 27.3333 14.6663 27.3333H25.333C25.5219 27.3333 25.6802 27.3973 25.808 27.5252C25.9358 27.6532 25.9997 27.8117 25.9997 28.0009C25.9997 28.19 25.9358 28.3483 25.808 28.4756C25.6802 28.603 25.5219 28.6666 25.333 28.6666H14.6663Z" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.99967 20.6666C8.14839 20.6666 6.57445 20.0184 5.27787 18.7218C3.9813 17.4252 3.33301 15.8513 3.33301 14C3.33301 12.2752 3.91421 10.7837 5.07661 9.52561C6.23899 8.2675 7.69454 7.54186 9.44327 7.34868C10.1031 6.09912 11.0069 5.1175 12.1548 4.40381C13.3027 3.69015 14.5843 3.33331 15.9997 3.33331C17.9142 3.33331 19.5591 3.96792 20.9343 5.23715C22.3095 6.50639 23.1099 8.08801 23.3356 9.98201C24.9715 10.0812 26.2689 10.6504 27.2279 11.6897C28.1869 12.729 28.6663 13.9436 28.6663 15.3333C28.6663 16.812 28.1471 18.0705 27.1086 19.1089C26.0702 20.1474 24.8117 20.6666 23.333 20.6666H9.99967ZM23.333 19.3333H9.99967C8.52276 19.3333 7.26464 18.8137 6.22531 17.7743C5.186 16.735 4.66634 15.4769 4.66634 14C4.66634 12.5453 5.18044 11.3038 6.20864 10.2756C7.23686 9.24741 8.47832 8.71109 9.93301 8.66665H10.3304L10.4843 8.27691C10.98 7.17947 11.7223 6.3034 12.7112 5.64871C13.7001 4.994 14.7963 4.66665 15.9997 4.66665C17.6544 4.66665 19.068 5.25297 20.2407 6.42561C21.4134 7.59828 21.9997 9.01196 21.9997 10.6666V11.3333H23.333C24.4373 11.3333 25.38 11.7239 26.1612 12.5051C26.9424 13.2863 27.333 14.229 27.333 15.3333C27.333 16.4376 26.9424 17.3803 26.1612 18.1615C25.38 18.9427 24.4373 19.3333 23.333 19.3333Z"
                    />
                </g>
            </g>
        </svg>
    );
}
