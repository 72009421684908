import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef } from "react";

import { ConsumerTripContentItemProductListLayout } from "@holibob-packages/graphql-types";

import { ConsumerTripContentItemProductListItemFragment } from "../../apiHooks/graphql";
import { ConsumerTripProductsQueryResultEntry } from "../../apiHooks/useConsumerTripProductsQuery";
import { ShelfDisplayEvent } from "../../custom-events";
import {
    ConsumerTripShelfProductSlider,
    ConsumerTripShelfProductSliderProps,
    ConsumerTripShelfProductSliderSkeleton,
} from "./ConsumerTripShelfProductSlider";
import { useConsumerTripHeaderData } from "./common/useConsumerTripHeaderData";

export type ConsumerTripProductSliderRule = Omit<ConsumerTripContentItemProductListItemFragment, "layout"> & {
    layout: ConsumerTripContentItemProductListLayout.Slider;
};

export type ConsumerTripShelfProductSliderContainerProps = Omit<
    ConsumerTripShelfProductSliderProps,
    "products" | "title" | "subTitle" | "Icon"
> & {
    consumerTripProductSliderRule: ConsumerTripProductSliderRule;
} & {
    products: ConsumerTripProductsQueryResultEntry;
};

export const ConsumerTripShelfProductSliderContainer = forwardRef(function Component(
    {
        consumerTripProductSliderRule,
        maxProducts = 8,
        mode,
        products,
        ...otherProps
    }: ConsumerTripShelfProductSliderContainerProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const { type, typeParams } = consumerTripProductSliderRule;
    const headerData = useConsumerTripHeaderData({ type, typeParams });
    const contentRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => contentRef.current!);

    useEffect(() => {
        if (!products.loading && products.data.length > 1) {
            contentRef.current?.dispatchEvent(
                new ShelfDisplayEvent({
                    itemList: products.data.map(({ id, name }) => ({ id: id, name: name ?? "" })),
                    shelfType: type,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, products.loading]);

    if (products.loading) {
        return <ConsumerTripShelfProductSliderSkeleton ref={contentRef} mode={mode} maxProducts={maxProducts} />;
    }

    return (
        <ConsumerTripShelfProductSlider
            maxProducts={maxProducts}
            ref={contentRef}
            {...otherProps}
            products={products.data}
            {...headerData}
        />
    );
});
