"use client";

import { Typography } from "@mui/material";
import MuiPagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import React, { ForwardedRef, forwardRef, useEffect, useReducer } from "react";
import { useDebounce } from "use-debounce";

import { ProductListFilter } from "@holibob-packages/graphql-types";

import { useCreateContextForRequestOptions } from "../apiHooks";
import { useProductListQuery } from "../apiHooks/graphql";
import { ProductItemFragment } from "../gql-request";
import { useIsBreakpoint, useNextTranslation } from "../hooks";
import { ProductCardV2Props, ProductsContainerGrid } from "../product";
import { ProductItem } from "../types";
import { HbmlCurationProductsProductHrefFactory } from "./HbmlComponents";
import { MarketPlaceProductTileHorizontalSkeleton } from "./MarketPlace/MarketPlaceTile/MarketPlaceProductTileHorizontalSkeleton";
import { SearchByPhraseLayout } from "./SearchByPhraseLayout";
import { CurationProductsGridSkeleton, PaginationContainer, ProductListContentWrapper } from "./index";

export type ProductSearchByPhraseLayoutProps = {
    isHeaderBackground?: boolean;
    filters?: ProductListFilter;
    currency?: string;
    onClose?: () => void;
    onSearch?: (phrase: string) => void;
} & Pick<ProductListComponentProps, "productHrefFactory" | "ProductCard" | "onProductClick">;

type ProductListComponentProps = {
    ProductCard: React.ComponentType<ProductCardV2Props>;
    productHrefFactory?: HbmlCurationProductsProductHrefFactory;
    productList: ProductItemFragment[];
    noProductFoundMessage: string;
    currency?: string;
    onProductClick?: (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        data: { item: ProductItem & { slug: string | null }; position: number }
    ) => void;
};

type PaginationContentProps = {
    page: number;
    pageCount: number; //Total pages
    handlePageChange: (_: unknown, page: number) => void;
};

type State = {
    page: number;
    searchPhrase: string;
};

type Action =
    | { type: "CLOSE_MODAL" }
    | { type: "SET_PAGE"; payload: number }
    | { type: "SET_SEARCH_PHRASE"; payload: string };

const initialState: State = {
    searchPhrase: "",
    page: 1,
};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "CLOSE_MODAL":
            return { ...initialState };
        case "SET_SEARCH_PHRASE":
            return { ...state, searchPhrase: action.payload, page: 1 };
        case "SET_PAGE":
            return { ...state, page: action.payload };
        default:
            return state;
    }
};

export const ProductSearchByPhraseLayout = forwardRef(function ProductSearchByPhraseLayoutInternal(
    {
        ProductCard,
        onClose,
        isHeaderBackground,
        onProductClick,
        currency,
        onSearch,
        ...props
    }: ProductSearchByPhraseLayoutProps,
    ref: ForwardedRef<HTMLDivElement | null>
) {
    const [t] = useNextTranslation("product");
    const [state, dispatch] = useReducer(reducer, initialState);
    const [searchPhrase] = useDebounce(state.searchPhrase, 500);
    const createContextForRequestOptions = useCreateContextForRequestOptions();

    const searchInput = {
        filter: {
            search: searchPhrase,
            ...props.filters,
        },
        page: state.page,
        pageSize: 21,
    };

    const { data, loading } = useProductListQuery({
        variables: searchInput,
        context: createContextForRequestOptions({ currency }),
    });

    useEffect(() => {
        if (searchPhrase) {
            onSearch?.(searchPhrase);
        }
    }, [onSearch, searchPhrase]);

    const handlePageChange = (_: unknown, pageCount: number) => dispatch({ type: "SET_PAGE", payload: pageCount });
    const handleSearchPhraseChange = (phrase: string) => {
        dispatch({ type: "SET_SEARCH_PHRASE", payload: phrase });
    };
    const handleOnClose = () => {
        if (onClose) {
            onClose();
            dispatch({ type: "CLOSE_MODAL" });
        }
    };

    const productsPageCount = data?.productList?.pageCount ?? 0;
    const products = data?.productList?.products ?? [];
    return (
        <SearchByPhraseLayout
            ref={ref}
            searchPhrase={state.searchPhrase}
            onSearchPhraseChange={handleSearchPhraseChange}
            placeholder={t("productSearch.placeholder")}
            isHeaderBackground={isHeaderBackground}
            onClose={handleOnClose}
        >
            {loading ? (
                <ProductListSkeleton />
            ) : (
                <ProductListComponent
                    productList={products}
                    ProductCard={ProductCard}
                    {...props}
                    currency={currency}
                    onProductClick={onProductClick}
                    noProductFoundMessage={t("message.noProductFound")}
                />
            )}
            {productsPageCount > 1 && (
                <PaginationContent
                    pageCount={productsPageCount}
                    page={state.page}
                    handlePageChange={handlePageChange}
                />
            )}
        </SearchByPhraseLayout>
    );
});

const ProductListSkeleton = () => {
    const isSmallScreen = useIsBreakpoint("sm");
    const skeletons = Array.from({ length: 6 });
    return isSmallScreen ? (
        <ProductListContentWrapper>
            {skeletons.map((_, index) => (
                <MarketPlaceProductTileHorizontalSkeleton key={index} />
            ))}
        </ProductListContentWrapper>
    ) : (
        <CurationProductsGridSkeleton />
    );
};

const ProductListComponent = ({
    productList,
    ProductCard,
    onProductClick,
    productHrefFactory,
    noProductFoundMessage,
    currency,
    ...props
}: ProductListComponentProps) => {
    const isSmallScreen = useIsBreakpoint("sm");

    if (!productList.length) return <Typography>{noProductFoundMessage}</Typography>;

    return (
        <ProductsContainerGrid>
            {productList.map((entry: ProductItemFragment, position) => {
                const { id, name, slug } = entry;
                const href = productHrefFactory ? productHrefFactory({ slug, id }) : "";
                const productItem = { id, name: name!, slug };

                return (
                    <ProductCard
                        {...entry}
                        currency={currency}
                        key={entry.id}
                        orientation="vertical"
                        href={href}
                        height={isSmallScreen ? 130 : 230}
                        {...props}
                        onClick={(event) => onProductClick?.(event, { item: productItem, position })}
                        {...(isSmallScreen && { isSmallTile: true, imageMaxWidth: 500 })}
                    />
                );
            })}
        </ProductsContainerGrid>
    );
};

const PaginationContent = (props: PaginationContentProps) => {
    const { pageCount, page, handlePageChange } = props;
    return (
        <PaginationContainerStyled>
            <PaginationContainer align="right" pageCount={pageCount}>
                <MuiPagination page={page} count={pageCount} color="primary" onChange={handlePageChange} />
            </PaginationContainer>
        </PaginationContainerStyled>
    );
};

const PaginationContainerStyled = styled("div")(({ theme }) => ({
    marginTop: theme.spacing(2),
}));
