export function deepObjectWithoutTypename<T>(data: T): DeepOmitTypename<T> {
    if (Array.isArray(data)) {
        return data.map(deepObjectWithoutTypename) as DeepOmitTypename<T>;
    }

    if (typeof data === "object" && data !== null) {
        return Object.fromEntries(
            Object.entries(data).flatMap(([key, value]) => {
                if (key === "__typename") {
                    return [];
                }

                return [[key, deepObjectWithoutTypename(value)]];
            })
        ) as DeepOmitTypename<T>;
    }
    return data as DeepOmitTypename<T>;
}

export type DeepOmitTypename<T> = T extends (infer U)[]
    ? DeepOmitTypename<U>[]
    : T extends Record<string, unknown>
      ? Omit<{ [K in keyof T]: DeepOmitTypename<T[K]> }, "__typename">
      : T;
