import { Card, CardProps } from "@mui/material";
import MuiModal, { ModalProps } from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import { ForwardedRef, forwardRef, ReactNode } from "react";

const ModalWrapper = styled(MuiModal)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});
/**
 * @deprecated
 *
 * Please use the Modal component from inside the Modal folder.
 */
export const ModalDeprecated = forwardRef(function ModalComponent(
    props: ModalProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    return (
        <ModalWrapper
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            closeAfterTransition
            ref={ref}
            {...props}
        />
    );
});

const Wrapper = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    maxWidth: `calc(100vw - ${theme.spacing(2)})`,
    maxHeight: `calc(100vh - ${theme.spacing(2)})`,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    boxSizing: "border-box",
}));

const Header = styled("header")({
    flexGrow: 0,
    flexShrink: 0,
});

const Footer = styled("footer")({
    flexGrow: 0,
    flexShrink: 0,
});
const Content = styled("div")({
    flexGrow: 100,
    overflow: "auto",
});

export type ModalLayoutProps = {
    header?: ReactNode;
    footer?: ReactNode;
} & CardProps;
/**
 * @deprecated
 *
 * Please use the Modal component from inside the Modal folder.
 */
export const ModalLayout = forwardRef(function ModalLayoutInner(
    { footer, header, children, ...props }: ModalLayoutProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    return (
        <Wrapper {...props} ref={ref}>
            {header && <Header>{header}</Header>}
            <Content data-ref="modal-content">{children}</Content>
            {footer && <Footer>{footer}</Footer>}
        </Wrapper>
    );
});
/**
 * @deprecated
 *
 * Please use the Modal component from inside the Modal folder.
 */
export const ModalTitle = styled("h1")(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize * 2,
    fontWeight: theme.typography.fontWeightLight,
    margin: 0,
}));
