import { forwardRef, useRef } from "react";
import { MarkRequired } from "ts-essentials";

import {
    ConsumerTripContentItemProductListLayout,
    ConsumerTripContentItemProductListType,
} from "@holibob-packages/graphql-types";

import { ConsumerTripProductsQueryResult } from "../../apiHooks/useConsumerTripProductsQuery";
import { ShelfProductClickEvent } from "../../custom-events";
import { ShelfFirstScrollEvent } from "../../custom-events";
import { ConsumerTripContentItemProductListItemFragment } from "../../gql-request";
import { useOnlyFirstCall } from "../../hooks";
import { ProductsContainerSliderProps } from "../../product/ProductsContainerSlider";
import { ConsumerTripContainerContentProps } from "./ConsumerTripContainer";
import {
    ConsumerTripShelfProductSliderContainer,
    ConsumerTripShelfProductSliderContainerProps,
} from "./ConsumerTripShelfProductSliderContainer";

export type ConsumerTripShelfContainerProps = MarkRequired<ConsumerTripContainerContentProps, "ProductCardComponent"> &
    Omit<ProductsContainerSliderProps, "sliderItemWidth" | "children" | "onScroll"> & {
        rules: ConsumerTripContentItemProductListItemFragment[];
        products: ConsumerTripProductsQueryResult;
    };

export const ConsumerTripShelfContainer = forwardRef(function Component({
    rules,
    products,
    ...otherProps
}: ConsumerTripShelfContainerProps) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const productSliderRules = rules.filter((x) => x.layout === ConsumerTripContentItemProductListLayout.Slider);

    return (
        <>
            {productSliderRules.map((x) => (
                <Slider
                    key={x.type}
                    consumerTripProductSliderRule={x}
                    {...otherProps}
                    products={products[x.type]!}
                    shelfType={x.type}
                />
            ))}
        </>
    );
});

function Slider({
    shelfType,
    ...otherProps
}: Omit<ConsumerTripShelfProductSliderContainerProps, "onScroll" | "onProductClick"> & {
    shelfType: ConsumerTripContentItemProductListType;
}) {
    const ref = useRef<HTMLDivElement>(null);

    const onFirstScroll = useOnlyFirstCall(() => {
        ref.current?.dispatchEvent(new ShelfFirstScrollEvent(shelfType));
    });

    return (
        <ConsumerTripShelfProductSliderContainer
            ref={ref}
            {...otherProps}
            onProductClick={(event, data) => {
                event.target.dispatchEvent(new ShelfProductClickEvent({ ...data, shelfType }));
            }}
            onScroll={onFirstScroll}
        />
    );
}
