import { ComponentProps } from "react";

export function HailWeather(props: ComponentProps<"svg">) {
    return (
        <svg {...props} width="32" height="32" viewBox="0 0 32 32">
            <g>
                <g>
                    <path d="M9.93301 23.8C9.93301 24.4259 9.4256 24.9333 8.79967 24.9333C8.17375 24.9333 7.66634 24.4259 7.66634 23.8C7.66634 23.1741 8.17375 22.6666 8.79967 22.6666C9.4256 22.6666 9.93301 23.1741 9.93301 23.8Z" />
                    <path d="M13.5333 27.8C13.5333 28.4259 13.0259 28.9333 12.3999 28.9333C11.774 28.9333 11.2666 28.4259 11.2666 27.8C11.2666 27.1741 11.774 26.6666 12.3999 26.6666C13.0259 26.6666 13.5333 27.1741 13.5333 27.8Z" />
                    <path d="M17.1329 23.8C17.1329 24.4259 16.6255 24.9333 15.9995 24.9333C15.3736 24.9333 14.8662 24.4259 14.8662 23.8C14.8662 23.1741 15.3736 22.6666 15.9995 22.6666C16.6255 22.6666 17.1329 23.1741 17.1329 23.8Z" />
                    <path d="M20.7331 27.8C20.7331 28.4259 20.2257 28.9333 19.5998 28.9333C18.9739 28.9333 18.4665 28.4259 18.4665 27.8C18.4665 27.1741 18.9739 26.6666 19.5998 26.6666C20.2257 26.6666 20.7331 27.1741 20.7331 27.8Z" />
                    <path d="M24.3327 23.8C24.3327 24.4259 23.8253 24.9333 23.1994 24.9333C22.5735 24.9333 22.0661 24.4259 22.0661 23.8C22.0661 23.1741 22.5735 22.6666 23.1994 22.6666C23.8253 22.6666 24.3327 23.1741 24.3327 23.8Z" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.99967 20.6666C8.14839 20.6666 6.57445 20.0184 5.27787 18.7218C3.9813 17.4252 3.33301 15.8513 3.33301 14C3.33301 12.2752 3.91421 10.7837 5.07661 9.52561C6.23899 8.2675 7.69454 7.54186 9.44327 7.34868C10.1031 6.09912 11.0069 5.1175 12.1548 4.40381C13.3027 3.69015 14.5843 3.33331 15.9997 3.33331C17.9142 3.33331 19.5591 3.96792 20.9343 5.23715C22.3095 6.50639 23.1099 8.08801 23.3356 9.98201C24.9715 10.0812 26.2689 10.6504 27.2279 11.6897C28.1869 12.729 28.6663 13.9436 28.6663 15.3333C28.6663 16.812 28.1471 18.0705 27.1086 19.1089C26.0702 20.1474 24.8117 20.6666 23.333 20.6666H9.99967ZM23.333 19.3333H9.99967C8.52276 19.3333 7.26464 18.8137 6.22531 17.7743C5.186 16.735 4.66634 15.4769 4.66634 14C4.66634 12.5453 5.18044 11.3038 6.20864 10.2756C7.23686 9.24741 8.47832 8.71109 9.93301 8.66665H10.3304L10.4843 8.27691C10.98 7.17947 11.7223 6.3034 12.7112 5.64871C13.7001 4.994 14.7963 4.66665 15.9997 4.66665C17.6544 4.66665 19.068 5.25297 20.2407 6.42561C21.4134 7.59828 21.9997 9.01196 21.9997 10.6666V11.3333H23.333C24.4373 11.3333 25.38 11.7239 26.1612 12.5051C26.9424 13.2863 27.333 14.229 27.333 15.3333C27.333 16.4376 26.9424 17.3803 26.1612 18.1615C25.38 18.9427 24.4373 19.3333 23.333 19.3333Z"
                    />
                </g>
            </g>
        </svg>
    );
}
