import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useRef, useState } from "react";

import { ProductListFilter } from "@holibob-packages/graphql-types";

import { DestinationProductSearchClickEvent, SearchTriggerEvent, DestinationSearchEvent } from "../../custom-events";
import { useNextTranslation } from "../../hooks";
import { ProductCardV2Props } from "../../product";
import { HbmlCurationProductsProductHrefFactory } from "../HbmlComponents";
import { ProductSearchModal } from "../ProductSearchModal";

type ConsumerTripSearchProductsProps = {
    productHrefFactory: HbmlCurationProductsProductHrefFactory;
    filters: ProductListFilter;
    ProductCardComponent: React.ComponentType<ProductCardV2Props>;
};

export const ConsumerTripSearchProducts = ({
    productHrefFactory,
    filters,
    ProductCardComponent,
}: ConsumerTripSearchProductsProps) => {
    const [t] = useNextTranslation("product");
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef<HTMLButtonElement>(null);

    const handleOpen = () => {
        setIsOpen(true);
        contentRef.current?.dispatchEvent(
            new SearchTriggerEvent({ searchButtonType: "DESTINATION_PRODUCT_SEARCH_HEADER_SEARCH_BAR" })
        );
    };

    const handleClose = () => setIsOpen(false);

    const dialogContentRef = useRef<HTMLDivElement>(null);

    const onSearch = (phrase: string) => {
        dialogContentRef.current?.dispatchEvent(new DestinationSearchEvent(phrase));
    };

    return (
        <>
            {isOpen && (
                <ProductSearchModal
                    productHrefFactory={productHrefFactory}
                    ProductCard={ProductCardComponent}
                    isOpen={isOpen}
                    onClose={handleClose}
                    filters={{ synchronisationSources: ["VIATOR", "MUSEMENT", "TIQETS"], ...filters }}
                    contentRef={dialogContentRef}
                    onProductClick={(event, data) => {
                        event.target.dispatchEvent(new DestinationProductSearchClickEvent(data));
                    }}
                    onSearch={onSearch}
                />
            )}
            <ConsumerSearchButton onClick={handleOpen} ref={contentRef}>
                <SearchIcon />
                <span>{t("productSearch.placeholder")}</span>
            </ConsumerSearchButton>
        </>
    );
};

const ConsumerSearchButton = styled(Button)(({ theme }) => ({
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    borderColor: "rgba(255, 255, 255, 0.4)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 40,
    color: theme.palette.common.white,
    padding: theme.spacing(0.5, 2),
    cursor: "pointer",
    marginTop: theme.spacing(),
    justifyContent: "flex-start",
    gap: theme.spacing(0.5),
    display: "none",
    fontWeight: 400,
    height: 34,
    "& span": {
        fontSize: 16,
    },
    [theme.breakpoints.up("sm")]: {
        display: "flex",
        padding: theme.spacing(0.5, 3),
    },
}));
