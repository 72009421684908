import { useRef, useState } from "react";

export const useOnMouseHold = (onMouseDown: () => void, onMouseUp: () => void): [boolean, () => void, () => void] => {
    const [isHoldActive, setIsHoldActive] = useState(false);
    const timeoutId = useRef<NodeJS.Timeout | undefined>();

    const onMouseDownHandler = () => {
        onMouseDown();
        setIsHoldActive(false);
        timeoutId.current = setTimeout(() => setIsHoldActive(true), 500);
    };

    const onMouseUpHandler = () => {
        onMouseUp();
        if (!isHoldActive) {
            clearTimeout(timeoutId.current);
        }
    };

    return [isHoldActive, onMouseDownHandler, onMouseUpHandler];
};
