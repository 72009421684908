import { styled } from "@mui/material/styles";

import { QuestionnaireV2Content } from "../QuestionnaireV2/QuestionnaireV2StepLayout";

export const RecommendationsQuestionnaireStepContent = styled(QuestionnaireV2Content)(({ theme }) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(2, 4),
    },
    backgroundColor: "#F5F7F9",
    boxSizing: "border-box",
    justifyContent: "center",
}));
